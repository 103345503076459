import React, { Component } from 'react';
import {Link} from "react-router-dom"
import promoImg from '../assets/images/hero_1.jpg';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import EmailIcon from '@material-ui/icons/Email';
class Footer extends Component {

    render() {
        return (
            <footer className="site-footer border-top " id="eefjksefjksf">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mb-8 mb-lg-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className="footer-heading mb-4">Navigations</h3>
                                </div>
                                {/* <div className="col-md-6 col-lg-4"> */}
                                    {/* <ul className="list-unstyled"> */}
                                        {/* <li><Link to="/student-tutor-forum">Student Forum</Link></li> */}
                                        {/* <li><Link to="/teacher-medium-forum">Teacher Forum</Link></li> */}
                                        {/* <li><a href="https://www.student1stop.com">Home</a></li> */}
                                        {/* <li><a href="#">Store builder</a></li> */}
                                    {/* </ul> */}
                                {/* </div> */}
                                <div className="col-md-6 col-lg-4">
                                    <ul className="list-unstyled">
                                        <li><Link to="/term-&-condition">Terms and Conditions </Link></li>
                                        <li><Link to="/privacy-policy">Privacy policy </Link></li>
                                        <li><Link to="/disclaimer">Disclaimer </Link></li>
                                        <li><Link to="/shipping-policy">Shipping policy </Link></li>
                                        <li><Link to="/card">View Cart</Link></li>
                                        {/* <li><Link to="">Website development</Link></li> */}
                                    </ul>
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    <ul className="list-unstyled">
                                        <li><a target="_blank" href="https://student-1-stop-vendor.herokuapp.com">Vender for Login</a></li>
                                        <li><Link to="/shipping-policy">Shipping and delivery policy</Link></li>
                                        {/* <li><a href="#">Software</Link></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
                            <h3 className="footer-heading mb-4">Promo</h3>
                            <a href="#" className="block-6">
                                <img src={promoImg} alt="Image placeholder" className="img-fluid rounded mb-4" />
                                <h3 className="font-weight-light  mb-0">Finding Your Perfect Shoes</h3>
                                <p>Promo from  nuary 15 &mdash; 25, 2019</p>
                            </a>
                        </div> */}
                        <div className="col-md-6 col-lg-3">
                            <div className="block-5 mb-5">
                                <h3 className="footer-heading mb-4">Contact Info</h3>
                                <ul className="list-unstyled">
                                    {/* <li className="address">Edwart Street Garden East, Karachi, Pakistan    </li> */}
                                    <li > <LocalPhoneIcon/><a href="tel://+923302757197">+92 330-2757197</a></li>
                                    <li><EmailIcon/> student1stop@gmail.com</li>
                                </ul>
                            </div>

                            {/* <div className="block-7">
                                <form>
                                    <label htmlFor="email_subscribe" className="footer-heading">Subscribe</label>
                                    <div className="form-group">
                                        <input type="text" className="form-control py-4" id="email_subscribe" placeholder="Email" />
                                        <input type="submit" className="btn btn-sm btn-primary" value="Send" />
                                    </div>
                                </form>
                            </div> */}
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col-md-12">
                            <p>
                                <a href="https://www.facebook.com/groups/1204431870028782"><FacebookIcon style={{color : "blue", fontSize : "25px"}}/></a>
                                <a href="https://www.instagram.com/student1stop/"><InstagramIcon style={{color : "orange", fontSize : "25px"}}/></a>
                            </p>
                        </div>

                    </div>
                    <div className="row text-center">
                        <div className="col-md-12">
                            <p>
                                Copyright All rights reserved 

                            </p>
                        </div>

                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;