import React, { useState , useEffect } from 'react';
import { Link  ,useHistory } from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import categ from '../assets/images/cate.jpeg';

import women from '../assets/images/women.jpg';
import u1 from '../assets/images/u1.jfif';

const SubCategoriesProduct = (props)=> {
    const [CategoriesUrl , setCategoriesUrl] = useState("")
    const [Product , setProduct] = useState([])
    const [Product1 , setProduct1] = useState([])
    const [Product2 , setProduct2] = useState([])
    const [Categories , setCategories] = useState([])
    const [fal , setFal] = useState(false)
    const [fal1 , setFal1] = useState(false)
    const [CategoriesName , setCategoriesname] = useState({})
    const [subCate , setsubCate] = useState([])
    const [allproduct , setallproduct] = useState([])
    const [Subproduct , setSubproduct] = useState([])
    const [SortedData , setSortedData] = useState([])
    const [SortedName , setSortedName] = useState([])
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [mainUser , setmainUser] = useState({})
    const [email ,  setemail ] = useState("")
    const [address ,  setaddress ] = useState("")
    const [user ,  setuser ] = useState("")
    // categoriesName subcategories
    let history = useHistory();


    useEffect(() =>{

        window.scrollTo(0, 0)

        if( JSON.parse(localStorage.getItem("user main")) )
        {
            setmainUser(JSON.parse(localStorage.getItem("user main")))
        setuser(JSON.parse(localStorage.getItem("user main")).user)
        setaddress(JSON.parse(localStorage.getItem("user main")).address)
        setemail(JSON.parse(localStorage.getItem("user main")).email)
        } 
        // console.log( props.match.params);
        // let Categoriesname = props.match.params ;
        // setCategoriesname(Categoriesname)

        // const Inter = setInterval(()=>{
            //  if (!JSON.parse(localStorage.getItem("CateProduct")) ){
            //  var data1 = JSON.parse(localStorage.getItem("Cate")) 

        // var Category =  props.match.url.split("/")
        // setCategoriesUrl(data1)
        // var Category1 =  CategoriesUrl.split("-").join(" ")


        fetch("https://student1stop-backend-vercel.vercel.app/slider-photo",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res=>res.json())
        .then(res1=>{
            // console.log(res1[0]);
            setSliderPhoto(res1)
        
        })



},[])













    
    


        return (
         
                            <div className="col-md-3 order-1 mb-5 mb-md-0">
                                <div className=" p-4 rounded mb-4">
                                    <h3 className="mb-3 h6 text-uppercase text-black d-block">Hello , {mainUser ? mainUser.user  : ""}</h3>
                                    {/* <ul className="list-unstyled mb-0">
                                    {
                                        subCate.map((res,i)=>{
                                                return( <li className="mb-1" key={i}>
                                                            <Link  className="d-flex">
                                                                <span>{res.CategoriesName}</span> 
                                                            </Link>
                                                        </li>

                                                )

                                            })
                                        }
                                    </ul> */}
                                </div>


                               
                                <div className="dispaly-block1">

                                <div className="p-4 rounded mb-4">
                                    <div className="mb-4">
                                        <div className="border p-4 rounded mb-4">
                                            <div className="mb-4">
                                                <h3 className="mb-3 h5 text-black d-block">Manage My Profile  </h3>
                                                
                                            </div>
                                            <div className="mb-4">
                                                <h3 className="mb-3 h6 text-grey d-block" onClick={()=>history.push("/user/dashboard")}>Dashboard</h3>
                                                <h3 className="mb-3 h6 text-grey d-block" onClick={()=>history.push("/user/dashboard/edit")}>My Profile</h3>
                                                {/* <h3 className="mb-3 h6 text-grey d-block" onClick={()=>history.push("/user/dashboard/edit")}>Address Book</h3> */}
                                            
                                                {/* <input type="checkbox" name="" id="" onChange={()=>setSortPrice("Low")}/> Low to High <br/>
                                                <input type="checkbox" name="" id="" onChange={()=>setSortPrice("A")}/> A to Z <br/> */}
                                            
                                            </div>
                                            <hr/>
                                            <div className="mb-4">
                                                <h3 className="mb-3 h5 text-black d-block">My Reviews  </h3>
                                                
                                            </div>
                                            <div className="mb-4">
                                                <h3 className="mb-3 h6 text-grey d-block">All Reviews</h3>
                                                {/* <h3 className="mb-3 h6 text-grey d-block">Address Book</h3> */}
                                            
                                                {/* <input type="checkbox" name="" id="" onChange={()=>setSortPrice("Low")}/> Low to High <br/>
                                                <input type="checkbox" name="" id="" onChange={()=>setSortPrice("A")}/> A to Z <br/> */}
                                            
                                            </div>
                                            <hr/>
                                            <div className="mb-4">
                                                <h3 className="mb-3 h5 text-black d-block">My Orders  </h3>
                                                
                                            </div>
                                            <div className="mb-4">
                                                <h3 className="mb-3 h6 text-grey d-block"  onClick={()=>history.push("/user/dashboard")}>All</h3>
                                                {/* <h3 className="mb-3 h6 text-grey d-block">Address Book</h3> */}
                                            
                                                {/* <input type="checkbox" name="" id="" onChange={()=>setSortPrice("Low")}/> Low to High <br/>
                                                <input type="checkbox" name="" id="" onChange={()=>setSortPrice("A")}/> A to Z <br/> */}
                                            
                                            </div>
                                                <hr/>
                                            <div className="mb-4">
                                                <h3 className="mb-3 h5 text-black d-block">My Chats  </h3>
                                                
                                            </div>
                                            <div className="mb-4">
                                                <h3 className="mb-3 h6 text-grey d-block" onClick={()=>history.push("/user/all-chats")}>All</h3>
                                                {/* <h3 className="mb-3 h6 text-grey d-block">Address Book</h3> */}
                                            
                                                {/* <input type="checkbox" name="" id="" onChange={()=>setSortPrice("Low")}/> Low to High <br/>
                                                <input type="checkbox" name="" id="" onChange={()=>setSortPrice("A")}/> A to Z <br/> */}
                                            
                                            </div>
                                                <hr/>
                                            <div className="mb-4">
                                                <h3 className="mb-3 h5 text-black d-block">My Post Ads  </h3>
                                                
                                            </div>
                                            <div className="mb-4">
                                                <h3 className="mb-3 h6 text-grey d-block" onClick={()=>history.push("/user/all-post-ad")}>All</h3>
                                                {/* <h3 className="mb-3 h6 text-grey d-block">Address Book</h3> */}
                                            
                                                {/* <input type="checkbox" name="" id="" onChange={()=>setSortPrice("Low")}/> Low to High <br/>
                                                <input type="checkbox" name="" id="" onChange={()=>setSortPrice("A")}/> A to Z <br/> */}
                                            
                                            </div>
                                                <hr/>
                                            <div className="mb-4">
                                                <h3 className="mb-3 h5 text-black d-block">My Send Request Ad</h3>
                                                
                                            </div>
                                            <div className="mb-4">
                                                <h3 className="mb-3 h6 text-grey d-block" onClick={()=>history.push("/user/all-send-request-ad")}>All</h3>
                                                {/* <h3 className="mb-3 h6 text-grey d-block">Address Book</h3> */}
                                            
                                                {/* <input type="checkbox" name="" id="" onChange={()=>setSortPrice("Low")}/> Low to High <br/>
                                                <input type="checkbox" name="" id="" onChange={()=>setSortPrice("A")}/> A to Z <br/> */}
                                            
                                            </div>
                                            {/* <hr/> */}
                                        
                                                        
                                        </div> 
                                      
                                    </div>
                                   
                                </div>
                            
                            </div>
                            </div>
                      
        )
    }



export default SubCategoriesProduct;