import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

const Contact = (props)=>  {


    useEffect(() => {
        window.scrollTo(0, 0)

        document.getElementById("mobile-site-hide-show11").style.display="none"
    }, []);




        return (
            <div>
                <div className="bg-light py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Privacy Policy</strong></div>
                        </div>
                    </div>
                </div>
                <div className="container" style={{width : "99% " ,padding : "80px 30px"}}>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div>
                                    <h1>Student1stop.com privacy policy</h1>
                                    <p>We collect your information to provide and continue to improve our products and services.
</p>
                                    <h3>Here are the types of personal information we collect:
</h3>
                                    <p>We will use the information you provide so that we can process your orders and provide you with the services and information provided through our website and that you request.
</p>
                                    <p>We do not collect customer credit card details because all credit card transactions.
As we do not collect customer credit card details then we cannot sell or rent your credit card number or transaction history to any third party.
</p>
<h3>What information do we collect?
</h3>
                                    <p>We collect information from you when you sign up for our site and place an order.
When ordering or registering on our site, as appropriate, you may be asked to enter your name: name, email address, billing address, telephone number.
</p>
<h3>For What Purpose Does Student1stop Use Your Personal Information?

</h3>
                                    <p>We use your information to operate, provide, and improve the products and services we offer our customers.</p>
                                    <h1>2. Cookies
</h1>
<p>Acceptance of cookies is not a requirement for visiting the Site. However, we would like to point out that the use of ‘basket’ functionality on the Site and ordering is only possible with the use of cookies. Cookies of small text files that identify your computer on our server as a unique user when you visit certain pages on the Site and are stored by your Internet browser on your computer's hard drive. Cookies may be used to view your Internet Protocol address, save time while you are turned on, or want to sign in to the Site. We only use cookies to make it easier to use the Site.</p>
                                    <h1>3. Security
</h1>
<p>We have appropriate technical and security measures in place to prevent unauthorized access to any lost or malicious damage or damage to your information. When we collect data through the Site, we collect your personal information from a secure server.</p>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        )
    
}


export default Contact;