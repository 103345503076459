import React, { useEffect , useState } from 'react';
import swal from 'sweetalert';
import cloth_1 from '../assets/images/cloth_1.jpg';
import shoe_1 from '../assets/images/shoe_1.jpg';
import cloth_2 from '../assets/images/cloth_2.jpg';
import cloth_3 from '../assets/images/cloth_3.jpg';
import Ban1 from "../assets/images/banner1.jpg"
import Ban2 from "../assets/images/banner2.jpg"
import Ban3 from "../assets/images/banner3.jpg"

import guides from "../assets/images/111.JPG"
import ChatIcon from '@material-ui/icons/Chat';



import z1 from "../assets/images/z1.jpeg"
import c1 from "../assets/images/z2.jpeg"
import guide from "../assets/images/Guide.JPG"

import ClearIcon from '@material-ui/icons/Clear';



import u1 from '../assets/images/u1.jfif';
import u2 from '../assets/images/u2.jfif';
import u3 from '../assets/images/u3.jfif';
// import u4 from '../assets/images/u4.jfif';





import blog_1 from '../assets/images/blog_1.jpg';


import { Link , Redirect} from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Button } from '@material-ui/core';
import GradeIcon from '@material-ui/icons/Grade';
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};


 const SchoolClassesCategoriesProductDetail =(props)=> {
    const [productDetail , setproductDetail] = useState([])
    const [Price , setPrice] = useState(1)
    const [DataPart2 , setDataPart2] = useState([])
    const [allproduct , setallproduct] = useState([])
    const [allproductReview , setallproductReview] = useState([])


    const [Area1 , setArea1] = useState(true)
    const [Area2 , setArea2] = useState(false)
    const [Area3 , setArea3] = useState(false)


    const [Star1 , setStar1] = useState(0)
    const [Star2 , setStar2] = useState(0)
    const [Star3 , setStar3] = useState(0)



    const [Changer , setChanger] = useState(0)


    const [name, setname] = useState("") 
    const [lname, setlname] = useState("") 
    const [email, setemail] = useState("") 
    const [subject, setsubject] = useState("") 
    const [Message, setMessage] = useState("") 
    const [Detail, setDetail] = useState("") 

    const [CategoriesName , setCategoriesName] = useState({})
    const [Average , setAverage] = useState(0)
    const [Check1 , setCheck1] = useState([])
    const [Che , setChe] = useState(0)
    const [Am , setAm] = useState(0)
    // const [q , setq] = useState(0)
    // const [p , setp] = useState(0)
    // const [v , setv] = useState(0)
    // const [qan , setqan] = useState(0)


    useEffect(() =>{
        window.scrollTo(0, 0)

        let Categoriesname = props.match.params ;
        setCategoriesName(Categoriesname)
        console.log("effe");
        fetch("https://student1stop-backend-vercel.vercel.app/all-user-post-ad/"+props.match.params.productid,{
        method: "GET",
         headers :  {
         "Content-Type" : "application/json" , 
     }
    })
    .then(res=>res.json())
    .then(res1=>{
        console.log(props.match.params,res1)
        let a = res1.BookName.filter((res2,r)=> res2 !== null)
        let b = res1.BookDisPrice.filter((res2,r)=> res2 !== null)
        let c = res1.BookPhoto.filter((res2,r)=> res2 !== null)
        let d = res1.BookCondition.filter((res2,r)=> res2 !== null)
        let e = res1.BookActualPrice.filter((res2,r)=> res2 !== null)
        setproductDetail([{...res1 ,BookName : a
            ,BookDisPrice : b
            ,BookPhoto : c
            ,BookCondition : d}])
            console.log(res1,[{...res1 ,BookName : a
                ,BookDisPrice : b
                ,BookPhoto : c
                ,BookActualPrice:e
                ,BookCondition : d}])
        // console.log(res1.Product_Long_Notes.replace("\n","<br>"));
        // setDetail(res1[0].Product_Long_Notes)
    })


        
        


    //   var data1 =  JSON.parse(localStorage.getItem("Cart")) 
    //    if (data1) setDataPart2(data1)


    },[])

const setMainPhoto = (e)=>{
    console.log(e);
    document.getElementById("mainphoto").style.display= "block"
    let  m = document.getElementById("mainphoto2")
    m.src = e
}

const setChanger1 = (e)=>{
    document.getElementById("myDiv1234").style.display= "none"
}
const setChanger2 = (e)=>{
    document.getElementById("mainphoto").style.display= "none"
}
const setChecker1 = (i)=>{
        let e = ! Check1[i]
        Check1[i]=e
        let f = []
    for (let j = 0; j < Check1.length; j++) {
        if(i !== j)
    f[j] = Check1[j]
    else f[j] = e
    }
    setCheck1( f)
    let a = 0
    let b = 0
    for (let j = 0; j < f.length; j++) {
        if(f[j]){
            a = a+ 1
            b = b + productDetail[0].BookDisPrice[j]
        }
    }
    // for (let j = 0; j < productDetail.length; j++) {
    //     if(a[j])
    //         a = a+ 1
    // }
    setChe(a)
    setAm(b)
}


const setsubmit = () =>{
    if(JSON.parse(localStorage.getItem("user main"))){
       if(Che > 0 ){
        // let f = []
        let a = []
        let b = []
        let c = []
        let d = []
        let e = []
        for (let j = 0; j < Check1.length; j++) {
            if(Check1[j]){
                console.log(j,productDetail)
                 a.push(productDetail[0].BookName[j])
                 b.push(productDetail[0].BookDisPrice[j])
                 c.push(productDetail[0].BookPhoto[j])
                 d.push(productDetail[0].BookCondition[j])
                 e.push(productDetail[0].BookActualPrice[j])
            }
        
        else{

        } 
        }
        console.log("books",
         CategoriesName.Classsessubcategories,
         CategoriesName.ClasssessubcategoriesProduct,
        JSON.parse(localStorage.getItem("user main"))._id ,
         productDetail[0].UserId ,
         a ,
         b ,
         c ,
         d ,
         e ,
         Am)
        fetch("https://student1stop-backend-vercel.vercel.app/user-buy-usecourse-post-the-ad",{
            method: 'POST' , 
            headers :  {
              "Content-Type" : "application/json" , 
            } , 
            body : JSON.stringify({
                MainCategories : "books",
                SubCategories : CategoriesName.Classsessubcategories,
                SubSubCategories : CategoriesName.ClasssessubcategoriesProduct,
                BuyId : JSON.parse(localStorage.getItem("user main"))._id ,
                SellerId : productDetail[0].UserId ,
                BookName : a ,
                BookDisPrice : b ,
                BookPhoto : c ,
                BookCondition : d ,
                BookActualPrice : e ,
                TotalAmount : Am
            })
          })
          .then((res)=>res.json())
          .then((res2)  =>{
              console.log(res2)
              if (res2){
                swal("Sucessfully Send the Reqyuest For Use Book         Please Wait For the Approval "  )
                setTimeout(()=>{
                    window.location.href = 'https://studentlocal.netlify.app/';
                    // window.location.href = 'https://student1stop.com/';
                    
                },1000)
                
              }
              else if (res2.Error){
                swal(res2.Error )
              }
              else{
                swal("Plz Try Again !"  )
              }
          })
          .catch((err)=>console.log(err))
    } 
    else{
        swal("Your Cart is Empty"  )
    }
    }
    else{
        swal("First You Sign In"  )
    }
    
}

    return (
        <>  
        <center>
            <div className="pop-up-1 dis-off" id="myDi">
                <h1>Product Updated in Cart</h1>
            </div> 
        </center>
        <div id="myDiv1234">
                <img src={guides} alt=""  id="myDiv23456"/>
                <ClearIcon id="myDiv34567"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "50%" , zIndex: "2"}} onClick={()=>setChanger1()}/>
            </div> 
      
    <div id="mainphoto">
                <img src={guides} alt=""  id="mainphoto2"/>
                <ClearIcon id="photo33"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "50%" , zIndex: "2"}} onClick={()=>setChanger2()}/>
            </div> 
        <div>
        
      
    

            <div className="bg-light py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">{CategoriesName.SchoolCoursecategoriesName}</strong><span className="mx-2 mb-0">/</span> <strong className="text-black">{CategoriesName.Classsessubcategories}</strong><span className="mx-2 mb-0">/</span> <strong className="text-black">{CategoriesName.productname}</strong><span className="mx-2 mb-0">/</span> <strong className="text-black">{CategoriesName.ClasssessubcategoriesProduct}</strong></div> 
                    </div>
                </div>
            </div>






                {
                    productDetail ? productDetail.map((res,i)=>{
                        return (
                                <div className="site-section">
                                    <div className="container">
                                            {productDetail[0].BookPhoto.map((res1,j)=>{
                                                // if(res1){
                                                    return (
                                                        <div className="row" style={{paddingBottom : "30px"}}>
                                                <div className="col-md-8 col-lg-6">
                                                    <div className="container-fuild">
                                                        <div className="col-xl-6 col-lg-6 col-md-8" style={{width : "100%", margin  : "0px",padding: "0px"}} >
                                                            <div className="container-photo-detail">
                                                                <img id="changes-Photo" src={res1} alt="First slide" onClick={()=>setMainPhoto(res.Product_Image_Upload)}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            <div className="col-md-12 col-lg-6">
                                                {/* <Link to={"/main-categories/"+CategoriesName.SchoolCoursecategoriesName+"/"+CategoriesName.Classsessubcategories}><p>{CategoriesName.Classsessubcategories}</p></Link> */}
                                            <div style={{display : "flex", }}>
                                                <div> <h5 className="text-black" style={{marginTop :  "30px"}}> <span style={{color : "grey"}}> Book Name : </span>{productDetail[0].BookName[j]}</h5>
                                                <h6 className=" h4" style={{color : "black", fontSize: "27px"}}><span style={{color : "grey", fontSize :"15px"}}>Book Condition : </span>{productDetail[0].BookCondition[j]}</h6>
                                                <h6 className="mb-4" style={{color : "black", fontSize: "27px"}}><span style={{color : "grey"}}>Rs : </span>{productDetail[0].BookDisPrice[j]}</h6>
                                                  <span style={{fontWeight : "bold"}}> Check to Buy </span><input type="checkbox" value={Check1[j]} onChange={()=>setChecker1(j)}  />
                                                       
                                            </div>
                                                {/* <div style={{padding: "64px 17px"}}>
                                                </div> */}
                                            </div>
                                              
                              
                                                {/* <p><div className="buy-now btn btn-sm btn-primary" onClick={() => addtocartproduct()}>Add To Bag</div></p> */}
                                               
                                                
                                            <hr />
                                            <br />
                                            {/* <hr /> */}
                                            </div>
                                            {/* <br /> */}
                                        </div>
                                                    )
                                                // }
                                            })
                                                }
                                                <br />
                                                <br />
                                                
                                                        <div style={{display : "flex" , justifyContent : "space-evenly"}}>
                                                            <div>  <h5> Total Book : <span style={{color : "black", fontSize: "27px"}}> {Che} </span></h5></div>
                                                            <div> <h5>Total Amount : <span style={{color : "black", fontSize: "27px"}}> {Am}</span></h5> </div>
                                                        </div>
                                                        <br />
                                            <div className="col-md-12 col-lg-6">

                                                 <center>
                                                 <button onClick={()=>setsubmit()}  className="btn btn-primary btn-lg btn-block" style={{background  : "rgb(36, 163, 181)"}} >Send Request</button>
                                                 </center>
                                    </div>
                                    </div>
                                </div>
                        )
                    })

                    : <center><h2>Loading ...</h2></center>
                }


                        {
                            
                        }















{/* 


                <div className="site-section block-3 site-blocks-2 bg-light">
                    <div className="container-fuild" style={{width : "97%"}}>
                        <div className="row justify-content-center">
                            <div className="col-md-7 site-section-heading text-center pt-4">
                                <h2 style={{marginLeft : "-74px"}} >Featured Products</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 p-5">
                            <Carousel responsive={responsive}>



                                        {
                                            allproduct.map((res,i)=>{
                                                var Cat = res.Product_Catagories.split(" ").join("-")
                                        
                                                var Cat1 = res.Product_Name.split(" ").join("-")
                                                return (
                                                     <div className="item" style={{margin : "5px 5px"}} key={i}>
                                                        <div className="block-4 text-center">
                                                            <figure className="block-3-image">
                                                                <img src={res.Product_Image_Upload} alt="Image placeholder" style={{height : "210px" }}  className="img-fluid"/>
                                                            </figure>
                                                            <div className="block-4-text p-4">
                                                                <h3><Link to='/shop'><div href="#">{res.Product_Name}</div></Link></h3>
                                                                <p className="mb-0">{res.Product_Title}</p>
                                                                <p className="text-primary font-weight-bold">{res.Product_Price}</p>
                                                                <Link to={"/shop/categories/"+Cat+"/"+Cat1} onClick={()=>savethedetailproduct(res)}><div href="#" className="btn btn-sm btn-primary">View</div></Link>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                </Carousel>
                            </div>
                        </div>
                    </div>
                </div> */}



        </div>
        </>
    )
}

export default SchoolClassesCategoriesProductDetail;
