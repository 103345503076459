import React , {useState , useEffect} from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

const Contact = (props)=>  {
    const [Main, setMain] = useState("books") 
    const [School, setSchool] = useState("") 
    const [email, setemail] = useState("") 
    const [Class, setClass] = useState("") 
    const [Message, setMessage] = useState("")

    const [Product_Sub_Catagories, setProduct_Sub_Catagories] = useState("") 
    const [Course_Sub_Catagories, setCourse_Sub_Catagories] = useState("") 

    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [AllAdminBookUpload , setAllAdminBookUpload] = useState([])
    const [OrderCate , setOrderCate] = useState([])
    const [ImageFor , setImageFor] = useState([])
    const [AftDisPrice , setAftDisPrice] = useState([])

    const [BookName , setBookName] = useState([])
    const [BookPrice , setBookPrice] = useState([])

    
    const [Product_Image_Upload , setProduct_Image_Upload] = useState([])
    const [url , seturl] = useState([])






    
        useEffect(() =>{
    
    
            fetch("https://student1stop-backend-vercel.vercel.app/CourseAllSubCategories",{
                method: "GET",
                  headers :  {
                  "Content-Type" : "application/json" , 
              } ,
            })
            .then(res=>res.json())
            .then(res2=>{
              console.log(res2)
              setSubClassCategories(res2)
            })
    
            fetch("https://student1stop-backend-vercel.vercel.app/AllCategories",{
                method: "GET",
                  headers :  {
                  "Content-Type" : "application/json" , 
              } ,
            })
            .then(res3=>res3.json())
            .then(res4=>{
              console.log(res4)
              setSubCategories(res4)
            })
    
            fetch("https://student1stop-backend-vercel.vercel.app/alladminbookusecourse",{
                method: "GET",
                  headers :  {
                  "Content-Type" : "application/json" , 
              } ,
            })
            .then(res3=>res3.json())
            .then(res4=>{
              console.log(res4)
              setAllAdminBookUpload(res4)
            })
    
    
    
    
    
    
        },[])

    const Submitdata = (e)=>{
        e.preventDefault()
        let f = 0
        // console.log("books" ,
        // BookName,BookPrice,
        //      Product_Sub_Catagories,
        //      Course_Sub_Catagories,
        //      JSON.parse(localStorage.getItem("user main"))._id ,
        //      AftDisPrice ,
        //      ImageFor ,
        //      OrderCate )
        // console.log(OrderCate ,ImageFor)
        if(OrderCate.length === ImageFor.length){
            OrderCate.map((res1,u)=>{
            if (res1 === "Book Condition") f = f + 1
        })
        if(f === 0){
            let a = []
        let b = []
        AllAdminBookUpload.map((res,i)=>{
            if(res.MainCategories === Main && res.SubCategories === Product_Sub_Catagories && res.SubSubCategories === Course_Sub_Catagories){
                a.push(res.BookName)
                b.push(res.BookActualPrice)
            }
        })
 

        // let fa = []

        // for (let j = 0; j < ImageFor.length; j++) {
        //     const data = new FormData()
    
        //     data.append("file", ImageFor[j])
        //     data.append("upload_preset", "commerce")
        //     data.append("cloud_name", "freedeveloperss123")
        //     fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
        //         method : "POST",
        //         body : data , 
        //     })
        //     .then(res=>res.json())
        //     .then((res2)=>{
        //       fa[j] = res2.url
        //     })
            
        // }
        // setImageFor ( fa)
        console.log("books" ,
        BookName,BookPrice,
             Product_Sub_Catagories,
             Course_Sub_Catagories,
             JSON.parse(localStorage.getItem("user main"))._id ,
            //  fa,
            //  a ,
             AftDisPrice ,
            // b ,
             ImageFor ,
             OrderCate )
             let x = BookName.filter((res2,r)=> res2 !== null)
             let y = BookPrice.filter((res2,r)=> res2 !== null)
             let c = ImageFor.filter((res2,r)=> res2 !== undefined)
             let d = OrderCate.filter((res2,r)=> res2 !== undefined)
             let e = AftDisPrice.filter((res2,r)=> res2 !== undefined)
           console.log(x,y,c,d,e)
        fetch("https://student1stop-backend-vercel.vercel.app/user-post-the-ad",{
            method: 'POST' , 
            headers :  {
              "Content-Type" : "application/json" , 
            } , 
            body : JSON.stringify({
                MainCategories : "books",
                SubCategories : Product_Sub_Catagories,
                SubSubCategories : Course_Sub_Catagories,
                UserId : JSON.parse(localStorage.getItem("user main"))._id ,
                BookName : x ,
                BookDisPrice : e ,
                BookActualPrice : y ,
                BookPhoto : c ,
                BookCondition : d ,
            })
          })
          .then((res)=>res.json())
          .then((res2)  =>{
              console.log(res2)
              if (res2){
                swal("Sucessfully Post Your Ad          Wait For the Approval "  )
                setTimeout(()=>{
                    window.location.href = 'https://studentlocal.netlify.app/';
                    // window.location.href = 'https://student1stop.com/';
                    
                },1000)
                
              }
              else if (res2.Error){
                swal(res2.Error )
              }
              else{
                swal("Plz Try Again !"  )
              }
          })
          .catch((err)=>console.log(err))
        }
        else{
            swal("Check the Correct Book Condition"  )
        }
    }
    else{
            swal("Some Feild Is Missing"  )
        }
        
        
        
    }


    const setChg = (e,i) =>{
        ImageFor[i]=e
        let f = []
    for (let j = 0; j < ImageFor.length; j++) {
        if(i !== j)
      f[j] = ImageFor[j]
      else {
        document.getElementById("waitasecond").style.display = "block"
        const data = new FormData()
        
        data.append("file", e)
        data.append("upload_preset", "commerce")
        data.append("cloud_name", "freedeveloperss123")
        fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
            method : "POST",
            body : data , 
        })
        .then(res=>res.json())
        .then((res2)=>{
            console.log("rgrg")
            f[j] = res2.url
            document.getElementById("waitasecond").style.display = "none"
        })
        // f[j] =  e
      }
    }
    setImageFor ( f)
  }
    const setChg1 = (e,i,u) =>{
        OrderCate[i]=e
        let f = []
    for (let j = 0; j < OrderCate.length; j++) {
        if(i !== j)
      f[j] = OrderCate[j]
      else f[j] = e
    }
    setOrderCate( f)

        AftDisPrice[i]=e
        let a = []
    for (let j = 0; j < AftDisPrice.length; j++) {
        if(i !== j)
      a[j] = AftDisPrice[j]
      else {
          if(e === "As Good As New"){
            a[j]   = u
          }
          if(e === "Slightly Used"){
            a[j] = u - (u * 0.05)

          }
          if(e === "Readable"){
            a[j] = u - (u * 0.10)

          }
        }
    }
    setAftDisPrice(a)
    // let aa = []
    // let b = []
    AllAdminBookUpload.map((res,j)=>{
        if(i === j && res.MainCategories === Main && res.SubCategories === Product_Sub_Catagories && res.SubSubCategories === Course_Sub_Catagories){
            BookName[i] = res.BookName
            BookPrice[i] = res.BookActualPrice
        }
    })
  }

        return (
            <div>
                <div style={{width: "100%",
    height: "100vh",
    position: "absolute",
    background: "aliceblue",
    textAlign: "center",
    fontSize: "40px",
    padding: "33% 0px",
    zIndex: "2"
    , display : "none"}} id="waitasecond">
                       Wait A Second ...
                </div>
                <div className="site-section">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-md-12">
                                <h2 className="h3 mb-3 text-black">Get In Touch</h2>
                            </div> */}
                            <div className="col-md-12">

                                <form onSubmit={(e)=>Submitdata(e)}>

                                    <div className="p-3 p-lg-5 border">
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="c_fname" className="text-black">Main Categories <span className="text-danger">*</span></label>
                                                <input type="text" value={Main} onChange={(e)=>setMain(e.target.value)}   className="form-control" />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="c_lname" className="text-black">Select Your  <span className="text-danger">*</span></label>
                                                {/* <input type="text" value={School} onChange={(e)=>setSchool(e.target.value)}   className="form-control"/> */}

                                        <select required id="c_country" className="form-control" value={Product_Sub_Catagories} onChange={(e)=>setProduct_Sub_Catagories(e.target.value)} >
                                            <option value="1">Select a Catagories</option>
                                            {
                                            SubCategories.map((res,i)=>{
                                                if(res.MainCategories === "books"){
                                                  return (
                                                <option  value={res.CategoriesName}>{res.CategoriesName}</option>
                                              )
                                                }
                                            })
                                            }
                                        </select>

                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_email" className="text-black">Select Your Class <span className="text-danger">*</span></label>
                                                {/* <input type="email" value={Class} onChange={(e)=>setClass(e.target.value)}   className="form-control" placeholder="" /> */}
                                                <select  id="c_country" className="form-control" value={Course_Sub_Catagories} onChange={(e)=>setCourse_Sub_Catagories(e.target.value)} >
                                                    <option value="1">Select a Catagories</option>
                                                    {
                                                      SubClassCategories.map((res,i)=>{
                                                        if(res.MainCategories === "books" && res.CategoriesName === Product_Sub_Catagories){

                                                        return (
                                                          <option required value={res.Second_Categories}>{res.Second_Categories}</option>
                                                        )
                                                        }
                                                      })
                                                    }
                                                   
                                                </select>
                                            
                                            </div>
                                        </div>
                                        {/* <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_subject" className="text-black">Subject </label>
                                                <input type="text" value={subject} onChange={(e)=>setsubject(e.target.value)}   className="form-control" />
                                            </div>
                                        </div> */}
<br />
<br />
                                        <div className="form-group row">
                                           { 
                                           AllAdminBookUpload.map((res,i)=>{
                                               console.log(res.MainCategories === Main , res.SubCategories === Product_Sub_Catagories, res.SubSubCategories === Course_Sub_Catagories)
                                               if(res.MainCategories === Main && res.SubCategories === Product_Sub_Catagories && res.SubSubCategories === Course_Sub_Catagories){
                                                  return(
                                                      <>
                                                    <div className='container-fuild'>
                                                        <div className='row'>
                                                            <div className='col-xl-4 col-lg-4 col-md-12'>
                                                              <b> <p style={{fontSize : "20px"}}>Book Title : <span style={{fontSize : "24px"}}>{res.BookName}</span></p> </b> 
                                                            </div>
                                                            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-5'>
                                                                <input type="file"   onChange={(e)=>setChg(e.target.files[0], i)}  className="form-control" id="inputAddress2" placeholder="Upload Image"/>
                                                                {/* <input type="file" onChange={(e)=>setProduct_Image_Upload(e.target.files[0])} className="form-control" id="inputAddress2" placeholder="Upload Image"/> */}
                                                            </div>
                                                            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-5'>
                                                            <select value={OrderCate[i]} onChange={(e)=>setChg1(e.target.value, i,res.BookActualPrice)} style={{padding : "8px"}}>
                                                            {/* <select value={OrderCate} onChange={(e)=>setOrderCate(e.target.value)} style={{padding : "8px"}}> */}
                                                                    <option value="Book Condition">Book Condition</option>
                                                                    <option value="As Good As New">As Good As New </option>
                                                                    <option value="Slightly Used">Slightly Used</option>
                                                                    <option value="Readable">Readable</option>
                                                            </select>
                                                            </div>
                                                            {AftDisPrice[i] ?
                                                                <div className='col-xl-2 col-lg-2 col-md-12'>
                                                             <b> <p style={{fontSize : "20px"}}>Rs : {AftDisPrice[i]}</p></b>
                                                            </div> 
                                                            :""
                                                            }
                                                           
                                                            {/* { OrderCate[i] === "As Good As New" ? 
                                                            <div className='col-xl-2 col-lg-2 col-md-12'>
                                                             <b> <p style={{fontSize : "20px"}}>Rs : {res.BookActualPrice}</p></b>
                                                            </div>
                                                            :  ""
                                                            }
                                                            { OrderCate[i] === "Slightly Used" ? 
                                                            <div className='col-xl-2 col-lg-2 col-md-12'>
                                                               <b> <p style={{fontSize : "20px"}}>Rs : {res.BookActualPrice - (res.BookActualPrice * 0.05)}</p></b>
                                                            </div>
                                                            :  ""
                                                            }
                                                            { OrderCate[i] === "Readable" ? 
                                                            <div className='col-xl-2 col-lg-2 col-md-12'>
                                                               <b> <p style={{fontSize : "20px"}}>Rs : {res.BookActualPrice - (res.BookActualPrice * 0.10)}</p></b>
                                                            </div>
                                                            :  ""
                                                            } */}
                                                        </div>
                                                    <hr />
                                                    <hr />
                                                    </div>
                                                    </>
                                               ) 
                                               }
                                               
                                           })
                                           }
                                        </div>


                                        {AftDisPrice[0] ?
                                        <div className="form-group row">
                                            <div className="col-lg-2">
                                                <center>
                                                    <h4>Total : {AftDisPrice.reduce((partialSum, a) => partialSum + a, 0)}</h4>
                                                </center>
                                            </div>
                                        </div>
                                        :""
                                                            }
                                        <div className="form-group row">
                                            <div className="col-lg-8">
                                                <input type="submit" className="btn btn-primary btn-lg btn-block" value="Upload Ad" style={{background  : "rgb(36, 163, 181)"}} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {/* <div className="col-md-5 ml-auto">
                                <div className="p-4 border mb-3">
                                    <span className="d-block text-primary h6 text-uppercase">New York</span>
                                    <p className="mb-0">203 Fake St. Mountain View, San Francisco, California, USA</p>
                                </div>
                                <div className="p-4 border mb-3">
                                    <span className="d-block text-primary h6 text-uppercase">London</span>
                                    <p className="mb-0">203 Fake St. Mountain View, San Francisco, California, USA</p>
                                </div>
                                <div className="p-4 border mb-3">
                                    <span className="d-block text-primary h6 text-uppercase">Canada</span>
                                    <p className="mb-0">203 Fake St. Mountain View, San Francisco, California, USA</p>
                                </div>

                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    
}


export default Contact;