import React, { useEffect , useState } from 'react';
import swal from 'sweetalert';
import  "../../src/style23.css"

import "react-multi-carousel/lib/styles.css";


import logo from './images/img1.jpg'
import x1 from './images/x1.jpg'







export function SubCategoriesProductDetails(props) {
    const [UserM , setUserM] = useState({})
    const [message , setmessage] = useState("")
    const [allmessage , setallmessage] = useState([])
    const [allshowmessage , setallshowmessage] = useState([])
    const [allvendor , setallvendor] = useState([])
    const [onevendor , setonevendor] = useState([])
    const [dis123 , setdis123] = useState(false)
    const [dis1 , setdis1] = useState(true)


    useEffect(() =>{
if(JSON.parse( localStorage.getItem("user main"))){
    fetch("https://student1stop-backend-vercel.vercel.app/allUser/"+JSON.parse(localStorage.getItem("user main"))._id,{
    method: "GET",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,
})
.then(res5=>res5.json())
.then(res6=>{
    console.log(res6)
    setUserM(res6)
    localStorage.setItem("user main" , JSON.stringify(res6))
 
    fetch("https://student1stop-backend-vercel.vercel.app/allUserVender34534",{
    method: "GET",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,
})
.then(res15=>res15.json())
.then(res16=>{
    const A = []
    const A1 = []
        res6.Chat.map((res8,i)=>{
            res16.map((res9,j)=>{
                if(res8.vendor_id === res9._id){
                 A.push(res9)
                }
            })
          })
          
          console.log(A)
          setallvendor(A)
          // if(A.length > 0)
          // setonevendor([A[0]])
          if(A.length === 0){
            if(JSON.parse( localStorage.getItem("Details Product"))[0].ProductCreater ){
              console.log(JSON.parse( localStorage.getItem("Details Product"))[0].ProductCreater )
              res16.map((res9,j)=>{
                if(JSON.parse( localStorage.getItem("Details Product"))[0].ProductCreater === res9._id){
                  A.push(res9)
                }
              })
              setallvendor(A)
              // setonevendor([A[0]])
              console.log(A)
            }
        }
})
fetch("https://student1stop-backend-vercel.vercel.app/allUserVender34534",{
  method: "GET",
   headers :  {
   "Content-Type" : "application/json" , 
} ,
})
.then(res151=>res151.json())
.then(res161=>{
  res161.map((res9,j)=>{
if(JSON.parse( localStorage.getItem("Details Product"))[0].ProductCreater === res9._id)
setonevendor([res9])
})
})
      fetch("https://student1stop-backend-vercel.vercel.app/AllUserShowMessage",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         }
        })
        .then(res=>res.json())
        .then(res1=>{
            setallmessage(res1)
            console.log(res1)
         const Chat1 = res1.filter((user,j)=>{
             if(JSON.parse( localStorage.getItem("Details Product"))[0]._id){
                 if(user.user_id === JSON.parse( localStorage.getItem("user main"))._id && user.product_id === JSON.parse( localStorage.getItem("Details Product"))[0]._id){
                    return user
                 }
             }
             else{
                 if( user.user_id === JSON.parse( localStorage.getItem("user main"))._id)  {
                    
                    return user
                }
             }
                
                })
                console.log(Chat1)
            setallshowmessage(Chat1)
        })
})

        // document.getElementById("fgjkln").style.display = "none"
        document.getElementById("eefjksefjksf").style.display = "none"
        
        
      }
      else{
        props.history.push("user/login")
      }
      
      
      
      
      
      return ()=>{
        
        document.getElementById("fgjkln").style.display = "block"
        document.getElementById("eefjksefjksf").style.display = "block"
}


    },[]) 

    const setMess1 = ( ) =>{
        console.log(message === "")
        if(message === ""){
            swal("Type a Message");
            return
        }
        const msgDetails = {
            message : message , 
            user_id :JSON.parse( localStorage.getItem("user main"))._id ,
            vendor_id :JSON.parse( localStorage.getItem("Details Product"))[0].ProductCreater ,
            product_id :JSON.parse( localStorage.getItem("Details Product"))[0]._id ,
            user : true,
            vendor : false,
        }
        console.log(msgDetails)
        let v = 0

        UserM.Chat.map((res,i)=>{
            if(res.product_id === msgDetails.product_id  && onevendor[0]._id ===  msgDetails.vendor_id ){
                v = v + 0
              }
              else{
                v = v + 1
              }
            })

        let m = 0

        onevendor[0].ChatName && onevendor[0].ChatName.map((res,i)=>{
          console.log(res.product_id , msgDetails.product_id  , res.user_id ,  msgDetails.user_id)
            if(res.product_id === msgDetails.product_id  && res.user_id ===  msgDetails.user_id){
               console.log("0")
               m = m + 0
              }
              else{
                console.log("1")
                m = m + 1
              }
            })
            console.log(m , onevendor[0].ChatName.length)
        if(onevendor[0].ChatName.length){
            if(m === onevendor[0].ChatName.length){

                fetch("https://student1stop-backend-vercel.vercel.app/newvendorchanger",{
        method: "POST",
        headers :  {
            "Content-Type" : "application/json" , 
        } ,
        body : JSON.stringify({
           ...msgDetails
        }),
    })
    .then(res5=>res5.json())
    .then((res8)=>{ 
        if(res8.Error){
            console.log("Message")
        }
    })
  }
  else{
          
                          fetch("https://student1stop-backend-vercel.vercel.app/newvendorchanger1",{
                  method: "POST",
                  headers :  {
                      "Content-Type" : "application/json" , 
                  } ,
                  body : JSON.stringify({
                     ...msgDetails
                  }),
              })
              .then(res5=>res5.json())
              .then((res8)=>{ 
                  if(res8.Error){
                      console.log("Message")
                  }
              })

        }
        
}   
        if(onevendor[0].ChatName){
        if(onevendor[0].ChatName.length === 0){
            fetch("https://student1stop-backend-vercel.vercel.app/newvendorchanger",{
        method: "POST",
        headers :  {
            "Content-Type" : "application/json" , 
        } ,
        body : JSON.stringify({
           ...msgDetails
        }),
    })
    .then(res5=>res5.json())
    .then((res8)=>{ 
        if(res8.Error){
            console.log("Message")

        }
    })
        }
        }



        if(v === UserM.Chat.length  && UserM.Chat.length !== 0){

                fetch("https://student1stop-backend-vercel.vercel.app/newuservendorchanger",{
        method: "POST",
        headers :  {
            "Content-Type" : "application/json" , 
        } ,
        body : JSON.stringify({
           ...msgDetails
        }),
    })
    .then(res5=>res5.json())
    .then((res8)=>{ 
      console.log(res8)
        if(res8.Error){
            console.log("Message")
        }
        else{
          const fg = res8.message.map((res78,y)=>{
            if(y = 0 ) return {
              ...res78 
            }
            else return res78
          })
            setUserM({
              ...UserM ,
              Chat : fg
            })
            localStorage.setItem("user main" , JSON.stringify({
              ...UserM ,
              Chat : fg
            }))

            fetch("https://student1stop-backend-vercel.vercel.app/allUserVender34534",{
    method: "GET",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,
})
.then(res15=>res15.json())
.then(res16=>{
    const A = []
            res16.map((res9,j)=>{
                if(msgDetails.vendor_id === res9._id){
                  A.push(res9)
                }
            })
     setallvendor([...A,...allvendor])
     if(!dis123)  setdis123(true)
     else setdis123(false)
})
        }
    })
} 



































else{
        if(UserM.Chat.length > 1)  {
      fetch("https://student1stop-backend-vercel.vercel.app/newvendorchanger2",{
        method: "POST",
        headers :  {
        "Content-Type" : "application/json" , 
      } ,
      body : JSON.stringify({
        ...msgDetails
        }),
      })
      .then(res5=>res5.json())
      .then((res8)=>{ 
        if(res8.message){
          const fg = res8.message.map((res78,y)=>{
            if(y = 0 ) return {
              ...res78 , new : false
            }
            else return res78
          })
let D = []
let S = []
          UserM.Chat.map((res9,c)=>{
  if(res9.vendor_id !== msgDetails.vendor_id || res9.product_id !== msgDetails.product_id ||  JSON.parse( localStorage.getItem("user main"))._id !== msgDetails.user_id ){
    D.push(res9)
  }
  else{
    S.push({...res9 , new : false})
  }
})
console.log(D)
const f = [...S , ...D]
fetch("https://student1stop-backend-vercel.vercel.app/allUserVender34534",{
  method: "GET",
   headers :  {
   "Content-Type" : "application/json" , 
} ,
})
.then(res15=>res15.json())
.then(res16=>{
  const A = []
  f.map((res56, h)=>{
    res16.map((res9,j)=>{
      if(res56.vendor_id === res9._id){
       A.push(res9)
      }
  })
  })
  console.log(allvendor,A ,onevendor ,A[0],f)
  setallvendor(A)
  setonevendor([A[0]])
  
  

  
  setUserM({
    ...UserM ,
    Chat : f
  // }

})
  localStorage.setItem("user main" , JSON.stringify({
    ...UserM ,
    Chat : f
  // }

}))
  
  
  console.log(f,S,A)
  const fas = [...allmessage , msgDetails]
  const Chat1 = fas.filter((user,j)=>{
    if( user.user_id === JSON.parse( localStorage.getItem("user main"))._id && user.product_id === S[0].product_id)  {
        return user
    }
    })
    console.log(Chat1)
    fetch("https://student1stop-backend-vercel.vercel.app/AllProduct1/"+ S[0].product_id,{
        method: "GET",
        headers :  {
            "Content-Type" : "application/json" , 
        }
    })
    .then(res=>res.json())
    .then((res2)=>{ 
        localStorage.setItem("Details Product" , JSON.stringify(res2))
    })
// setallshowmessage(Chat1)

setallshowmessage(Chat1)












})

          fetch("https://student1stop-backend-vercel.vercel.app/allUserVender34534",{
    method: "GET",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,
})
.then(res15=>res15.json())
.then(res16=>{


})
        }
      })

    }
    }




































        if(UserM.Chat.length === 0){
            fetch("https://student1stop-backend-vercel.vercel.app/newuservendorchanger",{
        method: "POST",
        headers :  {
            "Content-Type" : "application/json" , 
        } ,
        body : JSON.stringify({
           ...msgDetails
        }),
    })
    .then(res5=>res5.json())
    .then((res8)=>{ 
        // localStorage.setItem("user main" , JSON.stringify(
        //   {
        //     ...JSON.parse( localStorage.getItem("user main")) ,
            setUserM({
              ...UserM ,
              Chat : [{  product_id :JSON.parse( localStorage.getItem("Details Product"))[0]._id ,
                vendor_id :JSON.parse( localStorage.getItem("Details Product"))[0].ProductCreater ,        
                }]
            // }

          })
        // setallshowmessage([...allshowmessage , msgDetails])

        // ))
    //     fetch("https://student1stop-backend-vercel.vercel.app/allUserVender34534",{
    //       method: "GET",
    //        headers :  {
    //        "Content-Type" : "application/json" , 
    //    } ,
    //   })
    //   .then(res15=>res15.json())
    //   .then(res16=>{
    //     setonevendor(res16)
    //   })
    //     if(res8.Error){
    //         console.log("Message")
    //     }
    })
        }
       
        fetch("https://student1stop-backend-vercel.vercel.app/chatcreateuser",{
        method: "POST",
        headers :  {
            "Content-Type" : "application/json" , 
        } ,
        body : JSON.stringify({
           ...msgDetails
        }),
    })
    .then(res=>res.json())
    .then((res2)=>{ 
        console.log(res2)       
        if(res2){
            setallshowmessage([...allshowmessage , msgDetails])
            setallmessage([...allmessage , msgDetails])
            setmessage("")
            // swal("Successfully Submit your Review!");
        }
        else{
            swal(res2.Error);
        }
        console.log(res2)       
    })
    .catch(err=>console.log(err))

    }
const setallchanger = (onevenderdetail,i)=>{
    console.log(allmessage,UserM , i, onevenderdetail)
    setonevendor([onevenderdetail])
    const Chat1 = allmessage.filter((user,j)=>{
        if( user.user_id === JSON.parse( localStorage.getItem("user main"))._id && user.product_id === UserM.Chat[i].product_id)  {
            return user
        }
        })

        console.log(Chat1)
        fetch("https://student1stop-backend-vercel.vercel.app/AllProduct1/"+ UserM.Chat[i].product_id,{
            method: "GET",
            headers :  {
                "Content-Type" : "application/json" , 
            }
        })
        .then(res=>res.json())
        .then((res2)=>{ 
            localStorage.setItem("Details Product" , JSON.stringify(res2))
        })
    setallshowmessage(Chat1)




    const fg = UserM.Chat.map((res78,y)=>{
      if(y === i ){
         return {
        ...res78 , new : false
      }
      }
      else return res78
    })
    setUserM({
      ...UserM ,
      Chat : fg
    })
    localStorage.setItem("user main" , JSON.stringify({
      ...UserM ,
      Chat : fg
    })) 

    fetch("https://student1stop-backend-vercel.vercel.app/newuservendorchangerinClick",{
        method: "POST",
        headers :  {
            "Content-Type" : "application/json" , 
        } ,
        body : JSON.stringify({
           k :  fg , user_id :  JSON.parse( localStorage.getItem("user main"))._id
        }),
    })
    .then(res5=>res5.json())
    .then((res8)=>{ 
      console.log(res8)
          })









}
    return (
        <>  
        <div className="fgjgfgjkjl">
             <div className="container container4994" style={{padding :  "0px"}}>
        <div className="leftSide rtrtrtt">
            <div className="header">
                <div>
                <h5>All Vendor</h5>
                </div>
              
              </div>
             
              <div className="chatlist">
                {allvendor.map((res,i)=>{
                    return(
                     <div className="block" onClick={()=>setallchanger(res,i)}>
                        <div className="imgbx">
                          <img src={x1}  alt="imagem usuário" className="cover" />
                        </div>
                        <div className="details">

                            {UserM.Chat[i].new && UserM.Chat[i].new ? 
                          <div className="listHead" style={{flexDirection : "column"}}>
                            <h4>{res.Name}</h4>

                        <p  style={{color : "black" , fontWeight : "bold" , fontSize : "10px",}}>New message .</p>
                          </div>
                        
                        :
                        <div className="listHead">
                            <h4>{res.Name}</h4>

                          </div>
                        
                      }
                        </div>
                           {UserM.Chat[i].new && UserM.Chat[i].new ? 
                            <p className="time" style={{color : "green" , fontWeight : "bold" , fontSize : "20px"}}>◍</p>
                            :
                            ""
                            
                          }
                        
                      </div>
                        
                    )
                })
                    
                }
              
              </div>
        </div>

    {! dis1  ?
  <div className="leftSide rtrtrtt2323" style={{maxWidth : "157px"}}>
            <div className="header">
                <div>
                <p style={{ padding: "16px 25px 1px 9px",fontSize: "50px"}} onClick={()=>setdis1(dis1 ? false : true)}>≡</p>
                </div>
              
              </div>
             
              <div className="chatlist">
                {allvendor.map((res,i)=>{
                    return(
                        <div className="block" onClick={()=>setallchanger(res,i)}>
                        <div className="imgbx">
                          <img src={x1}  alt="imagem usuário" className="cover" />
                        </div>
                        <div className="details">
                        {UserM.Chat[i].new && UserM.Chat[i].new ? 
                          <div className="listHead" style={{flexDirection : "column"}}>
                            <h4>{res.Name}</h4>

                        <p  style={{color : "black" , fontWeight : "bold" , fontSize : "10px",}}>New message .</p>
                          </div>
                        
                        :
                        <div className="listHead">
                            <h4>{res.Name}</h4>

                          </div>
                        
                      }
                        </div>
                        {UserM.Chat[i].new && UserM.Chat[i].new ? 
                            <p className="time" style={{color : "green" , fontWeight : "bold" , fontSize : "20px"}}>◍</p>
                           :
                           ""

                           }
                      </div> 
                    )
                })
                    
                }
              
              </div>
        </div>


: 
          




        <div className="leftSide rtrtrtt2323" style={{maxWidth : "77px"}}>
            <div className="header">
                <div>
                {/* <h6>All Vendor</h6> */}
                <p style={{ padding: "16px 25px 1px 9px",fontSize: "50px"}} onClick={()=>setdis1(dis1 ? false : true)}>≡</p>
                </div>
              
              </div>
              {/* header */}
              {/* <div className="header">
                <div className="userimg">
                  <img src={logo} alt="Foto Usuário" className="cover" />
                </div> 
                <ul className="nav_icons">
                
                  <li>
                    <ion-icon name="chatbox" />
                  </li>
                  <li>
                    <ion-icon name="ellipsis-vertical" />
                  </li>
                </ul>
              </div> */}
              {/* search */}
              {/* <div className="search_chat">
                <div> */}
                  {/* <input type="text" placeholder="Search or start new chat" /> */}
                  {/* <ion-icon name="search-outline" /> */}
                {/* </div>
              </div> */}
              {/* chat list */}
              <div className="chatlist">
                {allvendor.map((res,i)=>{
                    return(
                        <div className="block" onClick={()=>setallchanger(res,i)}  style={{padding : "10px"}}>
                        <div className="imgbx" style={{margin : "0px"}}>
                          <img src={x1}  alt="imagem usuário" className="cover" />

                        </div>
                        {UserM.Chat[i].new && UserM.Chat[i].new ? 
                            <p className="time" style={{color : "green" , fontWeight : "bold" , fontSize : "20px"}}>◍</p>
                           :
                           ""

                           }
                      </div> 
                    )
                })
                    
                }
              
              </div>
        </div>
        }
      
      




        <div className="rightSide">
          <div className="header">

            {
            onevendor.map((res,i)=>{
                    return(
                
                
                <div className="imgText">
              <div className="userimg">
                <img src={x1} alt="Foto Usuário" className="cover" />
              </div>
              <h6> <b>{res.Name}</b>  <br /> 
              {/* <span>Online</span> */}
              </h6>

            </div>
              )
            })
                
            }
    
            {/* <ul className="nav_icons">
              <li>
                <ion-icon name="search-outline" />
              </li>
              <li>
                <ion-icon name="ellipsis-vertical" />
              </li>
            </ul> */}
          </div>
          {/* chatbox */}
          <div className="chatBox">
              {allshowmessage.map((res,i)=>{
                //   if(res.product_id === JSON.parse( localStorage.getItem("Details Product"))[0]._id){
                      if(res.user){
                      return(
                        <div className="message my_message">
                                <p>{res.message}<br />
                                {/* <span>12:15</span> */}
                                </p>
                        </div>

                  ) 
                  }
                  else{
                    return(
                        <div className="message frnd_message">
                            <p>{res.message}<br />
                            {/* <span>16:15</span> */}
                            </p>
                        </div>
                    )
                  }
                //   }
                  
                 
              })

              }
            {/* <div className="message my_message">
              <p>Hi<br /><span>12:15</span></p>
            </div>
            <div className="message my_message">
              <p>Hi<br /><span>12:15</span></p>
            </div>
            <div className="message my_message">
              <p>Hi<br /><span>12:15</span></p>
            </div>
            <div className="message my_message">
              <p>Hi<br /><span>12:15</span></p>
            </div>
            <div className="message my_message">
              <p>Hi<br /><span>12:15</span></p>
            </div>
            <div className="message frnd_message">
              <p>Helloooo, How are you ?<br /><span>16:15</span></p>
            </div> */}
          </div>
          {/* chat input */}
          
          <div className="chatbox_input">
            {/* <ion-icon name="happy-outline" /> */}
            {/* <ion-icon name="attach-outline" /> */}
            <input type="text" placeholder="Type a message ..." value={message} onChange={(e)=>setmessage(e.target.value)}  />
            <ion-icon name="paper-plane-outline" onClick={()=>setMess1()}/>
          </div>
        </div>
      </div> 
        </div>
            
        </>
    )
}

export default SubCategoriesProductDetails;
