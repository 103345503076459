import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import categ from '../assets/images/cate.jpeg';
import MainDashHaader from './MainDashHaader';

import women from '../assets/images/women.jpg';
import u1 from '../assets/images/u1.jfif';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const SubCategoriesProduct = (props)=> {

    const [CategoriesName , setCategoriesname] = useState({})

    const [SliderPhoto , setSliderPhoto] = useState([])
    const [order , setorder] = useState([])
    const [piece , setpiece] = useState([])
    const [total , settotal] = useState([])
    const [mainUser , setmainUser] = useState({})
    const [data , setdata] = useState([])

    // categoriesName subcategories

    useEffect(() =>{

        window.scrollTo(0, 0)

        if( JSON.parse(localStorage.getItem("user main")) ) setmainUser(JSON.parse(localStorage.getItem("user main")))

        console.log( props.match.params);
        let Categoriesname = props.match.params ;
        setCategoriesname(Categoriesname)

     
        fetch("https://student1stop-backend-vercel.vercel.app/alluserbuyusecourse123",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res=>res.json())
        .then(res2=>{
            const f = res2.filter((res3,f)=>res3.UserId === JSON.parse(localStorage.getItem("user main"))._id)
        console.log("ddwdwd",res2)
     setdata(f.reverse())
        })

        

        
},[])

const setMangeOrder = (data , i) =>{
    localStorage.setItem("User Order Details", JSON.stringify(data))
    props.history.push("/user/dashboard/order-detail")
}


const deleteProduct = (id,i) =>{
    fetch("/AllUserCreateDisbookusecourse/"+id ,{
      method: "DELETE" , 
      headers: {
        "Content-Type" : "application/json" ,
      }
    })
    .then(res=>res.json())
    .then(res2=>{
      const newData = data.filter(data=> data._id !== res2._id)
      setdata(newData)
    })
  }
  


        return (
            <div>
                     {data.length === 0&& data.length === 0?
                        <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={10000} 
        className="loader-1"
      /> 
      :""
}
<center>
            <div className="pop-up-1 dis-off" id="myDi">
                <h1>Product Updated in Cart</h1>
            </div> 
        </center>
               

                <div className="site-section">
                    <div className="container-fuild" style={{width : "97%" , margin : "0px auto"}}>

                        <div className="row mb-5">
                   


                <br/>
                <br/>
               
                  <div className="col-xl-8 order-2 col-lg-8 col-md-8">
                  <table className="table table-hover" style={{background : "white" , borderRadius : "10px"}}>
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col" colSpan="2">Main Cate</th>
                          <th scope="col" colSpan="2">Sub Cate</th>
                          <th scope="col" colSpan="2">Sub Sub Cate</th>
                          <th scope="col" colSpan="2">Book Name</th>
                          <th scope="col" colSpan="2">Actual Price</th>
                          <th scope="col" colSpan="2">Price Cut</th>
                          <th scope="col"  colSpan="3">Book Condition</th>
                          {/* <th scope="col">User Details</th> */}
                          {/* <th scope="col">Change</th> */}
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        data.map((res,i)=>{
                              return <>
                              <tr key={i}>
                                        <th>{i+1}</th>
                                        <td colSpan="2">{res.MainCategories}</td>
                                        <td colSpan="2">{res.SubCategories}</td>
                                        <td colSpan="2">{res.SubSubCategories}</td>
                                        <td colSpan="2">
                                            {res.BookName.map((res1,j)=>{
                                                return (
                                                    <>{res1} <br /> </>
                                                )
                                            })}
                                        </td>
                                        <td colSpan="2">
                                        {res.BookActualPrice.map((res1,j)=>{
                                                return (
                                                    <>{res1} <br /> </>
                                                )
                                            })}
                                            </td>
                                        <td colSpan="2">
                                        {res.BookDisPrice.map((res1,j)=>{
                                                return (
                                                    <>{res1} <br /> </>
                                                )
                                            })}
                                            </td>
                                        <td  colSpan="3">
                                        {res.BookCondition.map((res1,j)=>{
                                                return (
                                                    <>{res1} <br /> </>
                                                )
                                            })}
                                            </td>
                                       

                                    </tr>
                                   <tr>

                                        {res.BookPhoto.map((res1,j)=>{
                                            return (
                                                // <td>
                                                <img src={res1} alt="" style={{width : "400px" , height : "300px"}} /> 
                                                    // </td>
                                                )
                                            })}
                                   </tr>
                                    </>
                          })
                      }

                      {
                        data.length === 0 
                        ? 
                        <tr>
                          <th scope="col" colSpan="20" style={{textAlign : "center"}}><center>No data for showing</center> </th>
                        </tr>
                        :
                         ""
                      }
                      <tr>
                      <th scope="col"></th>
                          <th scope="col" colSpan="2">Main Cate</th>
                          <th scope="col" colSpan="2">Sub Cate</th>
                          <th scope="col" colSpan="2">Sub Sub Cate</th>
                          <th scope="col" colSpan="2">Book Name</th>
                          <th scope="col" colSpan="2">Actual Price</th>
                          <th scope="col" colSpan="2">Price Cut</th>
                          <th scope="col"  colSpan="2">Book Condition</th>
                          {/* <th scope="col" colSpan="2">User Details</th> */}
                          {/* <th scope="col" colSpan="2">Change</th> */}
                          <th scope="col" colSpan="2">Action</th>
                        </tr>
                      <tr>
                        </tr>
                      </tbody>
                    </table>
                    <table>
                        {/* <tbody>
                      {
                        data.map((res,i)=>{
                              return <>
                                   <tr>

                                        {res.BookPhoto.map((res1,j)=>{
                                            return (
                                                <td>
                                                <img src={res1} alt="" style={{width : "400px" , height : "300px"}} /> 
                                            </td>
                                                )
                                            })}
                                   </tr>
                                    </>
                          })
                      }
                      </tbody>  */}
                    </table>
                     
                  
     </div>

                            <MainDashHaader />

                        </div>







                        

                    </div>
                </div>
            </div>
        )
    }



export default SubCategoriesProduct;