import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';

import categ from '../assets/images/cate.jpeg';
import women from '../assets/images/women.jpg';

const SearchData = (props) =>{
    const [Product , setProduct] = useState([])
    const [Product1 , setProduct1] = useState([])
    const [Product2 , setProduct2] = useState([])
    const [Categories , setCategories] = useState([])
    const [fal , setFal] = useState(false)
    const [fal1 , setFal1] = useState(false)
    const [SortedData , setSortedData] = useState([])
    const [SortedName , setSortedName] = useState([])


    useEffect(() =>{
        window.scrollTo(0, 0)

        var data2 = JSON.parse(localStorage.getItem("SearchData")) 
        
            setProduct(data2)
                    
            fetch("https://student1stop-backend-vercel.vercel.app/MainCatogories",{
                method: "GET",
                    headers :  {
                    "Content-Type" : "application/json" , 
                } ,
            })
            .then(res4=>res4.json())
            .then(res5=>{
                setCategories(res5)
                // console.log(res1);
            })


            fetch("https://student1stop-backend-vercel.vercel.app/TagsSortArrival1111",{
                method: "GET",
                    headers :  {
                    "Content-Type" : "application/json" , 
                } ,
            })
            .then(res9=>res9.json())
            .then(res8=>{
                // const dta = res8.filter((res7,i)=>{
                //     if(res7.MainCategories ===Categoriesname.categoriesName  && res7.New_Sub_Categories === Categoriesname.subcategories) {
                //         console.log(res7);
                //         return res7
                //     }
                // })
                // console.log(dta);
                setSortedData(res8)
            })
 const da = setInterval(()=>{
             var data2 = JSON.parse(localStorage.getItem("SearchData")) 
            setProduct(data2)
        },3000)
    return () => clearInterval(da);


},[])






const savethedetailproduct = (data) =>{
    localStorage.setItem("Data" , JSON.stringify(data) )
    console.log(data);
 }



const SortData1 = (a ,b) =>{
            setFal1(false)

          const Pro6 = Product.sort((a, b)=> {
                return parseFloat(a.Product_Price) - parseFloat(b.Product_Price)  
                
            });
            setProduct1(Pro6)
            setFal(true)
            console.log(Product1);



            
}
const SortData2 = (a ,b) =>{
    setFal(false)
          const Pro6 = Product.sort((a, b)=> {
            var nameA = a.Product_Name.toUpperCase(); 
            var nameB = b.Product_Name.toUpperCase(); 
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
            });
            setProduct2(Pro6)
            setFal1(true)
            console.log(Product2);



            
}


// const cate =(Categories) =>{
//     var Cat1 = Categories.split(" ").join("-")

//     localStorage.setItem("Cate" , JSON.stringify(Categories) )
    
//     setTimeout(()=>{
//         props.history.push(`/shop/categories/${Cat1}`)
//     },1500)
// }



const sortedAlgo = (e)=>{
    let d = false
    let da = 0
    SortedName.map((res9,a)=>{
        if(res9 === e){
            d = true
            da = a
        }
    })
    if(d){
        SortedName.splice(da,1)
    }
    else{
        SortedName.push(e)
    }
    fetch("https://student1stop-backend-vercel.vercel.app/AllProduct",{
                    method: "GET",
                    headers :  {
                    "Content-Type" : "application/json" , 
                } ,
                })
                .then(res7=>res7.json())
                .then(res8=>{
                    // setallproduct(res8)
                    const NonActive = res8.filter((res9,i)=>{
                        // console.log(res9.status , res9.Product_Catagories , CategoriesName.categoriesName  ,res9.Product_Sub_Catagories , Categoriesname.subcategories);
                        if(res9.status && res9.Arrival){
                            return res9 
                        }
                    })
                
    if(SortedName.length > 0){
        let Coa = []
        SortedName.map((res22,x)=>{
      console.log(res22,SortedName)
//    console.log(Subproduct)
   const Sort = NonActive.filter((res,i)=>{
        res.Product_Name.split(" ").map((res1,i)=>{
            // console.log(res1)
            if (res1 === res22){
                Coa.push(res)
            }
        })
        res.Product_Title.split(" ").map((res1,i)=>{
            // console.log(res1)
            if (res1 === res22){
                Coa.push(res)
            }
        })
        
            res.tags.map((res1,i)=>{
                if (res1 === res22){
                    // console.log("true")
                    Coa.push(res)
                }
            })
        // }
   })  
    })
     let uniq = [...new Set(Coa)];

   console.log(uniq);
   setProduct(uniq)
   localStorage.setItem("SearchData",JSON.stringify(uniq))

}
else{
    setProduct(NonActive)
    localStorage.setItem("SearchData",JSON.stringify(NonActive))

    }
   
  })
}

    const setSortPrice = (e)=>{
        // setFal(false)
        SortedName.push(e)
        console.log(e);
        if (e === "Low"){
            const Pro6 = Product.sort((a, b)=> {
                    return parseFloat(a.Product_Price) - parseFloat(b.Product_Price)  
                    
                });
                setProduct(Pro6)

        }
        if (e === "A"){
            const Pro6 = Product.sort((a, b)=> {
                var nameA = a.Product_Name.toUpperCase(); 
                var nameB = b.Product_Name.toUpperCase(); 
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                return 0;
                });
                console.log(Pro6);
                setProduct(Pro6)

        }
        setFal(true)
    }



    
    
    const setDisplayBlock = ()=>{
        // console.log("fddddddddddddddddd")
       let data1 =  document.getElementById("dispaly-block")
       if(data1.style.display === "none"){
        data1.style.display = "block"
       }
       else{
        data1.style.display = "none"
       }
    }


        return (
            <div>

                <div className="bg-light py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Shop</strong> <span className="mx-2 mb-0">/</span><strong>Search Product</strong> </div>
                        </div>
                    </div>
                </div>

                <div className="site-section">
                    <div className="container">

                        <div className="row mb-5">
                            <div className="col-md-9 order-2">

                                <div className="row">
                                    <div className="col-md-12 mb-5">
                                        <div className="float-md-left mb-4"><h2 className="text-black h5">Shop All</h2></div>
                                        <div className="d-flex">
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-5">
                                {
                                    Product.length > 0 ?
                                    Product.map((res,i)=>{
                                        return (
                                            <div className="col-sm-6 col-lg-4 mb-4" data-aos="fade-up">
                                                <div className="block-4 text-center hover-product-card">
                                                <span style={{margin : "10px 4px" , color : "red", fontSize : "20px" , fontWeight : "bold",textAlign : "left"}}>
                                                {res.Size_Discounted[0] ?
                                                    <span>{100 - ( res.Size_Discounted[0]*100) /  res.Price[0]}  % off</span>
                                                    : 
                                                res.Product_Price_Discounted ?
                                                    <span>{100 - (res.Product_Price_Discounted *100) /  res.Product_Price}  % off</span>
                                                    : ""
                                                }
                                                </span>
                                                    <figure className="block-4-image">
                                                    {/* <Link to='/detail'> */}
                                                    <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res._id+"/"+res.Product_Name} ><img src={res.Product_Image_Upload}  style={{height : "210px" , width : "200px" }}  alt="Image placeholder" className="img-fluid" /></Link>
                                                    {/* </Link> */}
                                                    </figure>
                                                    {/* <div className="block-4-text p-4" onMouseEnter={()=>ViewAtDetails()} onMouseLeave={()=>NoViewAtDetails()} > */}
                                                    <div className="block-4-text p-4">
                                                        <h3><Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res._id+"/"+res.Product_Name}> {res.Product_Name}</Link></h3>
                                                        <p className="mb-0">{res.Product_Title}</p>
                                                        {
                                                            !res.Product_Price_Discounted ?
                                                            <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
                                                            <p  style={{color  : "black"  }}>Rs: {res.Price[0]}</p>
                                                            <div style={{width : "10px"}}></div>
                                                            <p style={{color  : "grey"  }}> <b>Size : {res.Size[0]}</b>  </p>
                                                        </div>                                                            
                                                        :
                                                            res.Product_Price_Discounted ? 
                                                        <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
                                                            <p  style={{color  : "black"  }}>Rs: {res.Product_Price_Discounted}</p>
                                                            <div style={{width : "10px"}}></div>
                                                            <p style={{color  : "grey"  }}> <b><s>Rs: {res.Product_Price}</s></b>  </p>
                                                        </div>
                                                        : 
                                                        <p style={{color  : "black"  }}>{res.Product_Price}</p>

                                                        }
                                                        <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res._id+"/"+res.Product_Name} ><div className="btn btn-dark btn-group-lg view-btn-detail">View</div></Link>
                                                        {/* <Link to={"/categories/"+CategoriesName.categoriesName+"/"+CategoriesName.subcategories+"/"+res._id+"/"+res.Product_Name} onClick={()=>savethedetailproduct(res)} id="view-at-hover" ><div className="btn btn-dark btn-group-lg view-btn-detail">View</div></Link> */}

                                                    </div>
                                                </div>
                                            </div> 
                                        )
                                    }) 
                                    :
                                     <span style={{margin:  "auto"}}>
                                        <h3>No Any Product</h3>
                                    </span>
                                   }
                                    


                                </div>
                               
                            </div>
                            <center>
                                    <h3 className="mb-3 h6 text-uppercase text-black display-6" id="display-6" onClick={()=>setDisplayBlock()}>Sort by Default</h3>
                                </center>
                                <div className="dispaly-block" id="dispaly-block">
                            <div className="border p-4 rounded mb-4">
                                    <div className="mb-4">
                                    <div className="border p-4 rounded mb-4">
                                    <div className="mb-4">
                                        <h3 className="mb-3 h6 text-uppercase text-black d-block">Sort Filter by </h3>
                                        
                                    </div>
                                    
                                    <hr/>
                                    {SortedData.length > 0 ? 
                                        SortedData[0].tagsName.map((res1,h)=>{
                                            if(res1 !== ""){
                                                console.log(res1)
                                             return (
                                                <div className="mb-4">
                                                    <h3 className="mb-3 h5 text-uppercase text-black d-block">{res1}</h3>
                                                    {
                                        SortedData.length > 0 ?
                                                        SortedData[0].tagsCategories[h].map((res3,r)=>{
                                                                console.log(r , h)
                                                                return (
                                                                    <div>
                                                                    <label><input type="checkbox" onChange={()=>sortedAlgo(res3)}/> {res3} </label>
                                                                    </div>


                                                                        )
                                                        
                                                    })
                                                    :""
                                                    }
                                                    {/* <input type="checkbox" name="" id=""/> Muhammad Umer <br/>
                                                    <input type="checkbox" name="" id=""/>Muhammad Ahmed <br/> */}
                                                
                                                </div>
                                            )
                                            }
                                            
                                        })
                                       

                                    : ""
                                       
                                    }
                                    
                                     {/* <div className="mb-4">
                                            <h3 className="mb-3 h5 text-uppercase text-black d-block">Vender</h3>
                                            <input type="checkbox" name="" id=""/> Muhammad Umer <br/>
                                            <input type="checkbox" name="" id=""/>Muhammad Ahmed <br/>
                                           
                                        </div> */}
                                    {/* <div className="mb-4">
                                        <h3 className="mb-3 h5 text-uppercase text-black d-block">Vender</h3>
                                        <input type="checkbox" name="" id=""/> Muhammad Umer <br/>
                                        <input type="checkbox" name="" id=""/>Muhammad Ahmed <br/>
                                       
                                    </div>
                                   
                                    <hr/>

                                    <div className="mb-4">
                                        <h3 className="mb-3 h5 text-uppercase text-black d-block">Gender</h3>
                                        <input type="checkbox" name="" id=""/> Male <br/>
                                        <input type="checkbox" name="" id=""/> Female <br/>
                                       
                                    </div>
                                   


                                </div>
                                        <h3 className="mb-3 h6 text-uppercase text-black d-block">Shoes Size</h3>
                                        <div className="Shoes-Size-Button">
                                            <button>UK-1</button>
                                            <button>UK-2</button>
                                            <button>UK-3</button>
                                            <button>UK-4</button>
                                            <button>UK-5</button>
                                            <button>UK-6</button>
                                            <button>UK-7</button>
                                            <button>UK-8</button>
                                            <button>UK-9</button>
                                            <button>UK-10</button>
                                            <button>UK-11</button>
                                            <button>UK-12</button>
                                            <button>UK-13</button>
                                            <button>1</button>
                                            <button>2</button>
                                            <button>3</button>
                                            <button>4</button>
                                            <button>5</button>
                                            <button>6</button>
                                            <button>7</button>
                                            <button>8</button>
                                            <button>9</button>
                                            <button>10</button>
                                            <button>11</button>

                                            */}                     
                                            </div> 
                                        {/* <div id="slider-range" className="border-primary"></div> */}
                                        {/* <input type="text" name="text" id="amount" className="form-control border-0 pl-0 bg-white" disabled="" /> */}
                                        {/* <button  className="btn btn-sm btn-primary" onClick={()=>SortData1("A","Z")}> Low to High</button> */}
                                    </div>
                                    </div>
                            
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        )
    }

export default SearchData
