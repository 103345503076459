import React , {useState , useEffect} from 'react';
import { Link  ,useHistory } from 'react-router-dom';


// import logo from '../assets/images/logo1.png';
import img1 from '../Pages/images/arr.jpeg';
// import Head1 from "../assets/images/header-bg-rpt.jpg"


import logo1 from '../assets/images/student1stop.com.png';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import MenuIcon from '@material-ui/icons/Menu';
import TagFacesSharpIcon from '@material-ui/icons/TagFacesSharp';
import LocalMallSharpIcon from '@material-ui/icons/LocalMallSharp';
import StarBorderSharpIcon from '@material-ui/icons/StarBorderSharp';
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import ExitToAppSharpIcon from '@mui/icons-material/ExitToAppSharp';
import { useSelector, useDispatch } from 'react-redux';


const Header =(props)=> {


    const [search , setSearch] = useState("")
    const [DataPart2 , setDataPart2] = useState([])
    const [UserDatat , setUserDatat] = useState({})
    const [userHeader , setuserHeader] = useState(false)
    const [menu1 , setmenu1] = useState(false)
    const [adminHeader , setadminHeader] = useState(false)
    const [displayMenu , setdisplayMenu] = useState(false)
    const [MainLine , setMainLine] = useState("")
    const [MainForum , setMainForum] = useState(false)
    const [MainCategories , setMainCategories] = useState([])
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [fvalue , setfvalue] = useState([])
    const [fvalue1 , setfvalue1] = useState([])
    const [fg , setfg] = useState(true)
    const [bht , setbht] = useState("")
    const [mainUser , setmainUser] = useState({})
    let history = useHistory();


    useEffect(() =>{
        if( JSON.parse(localStorage.getItem("user main")) ) setmainUser(JSON.parse(localStorage.getItem("user main")))
        fetch("https://student1stop-backend-vercel.vercel.app/main-line",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res=>res.json())
        .then(res1=>{
            // console.log(res1[0]);
            setMainLine(res1[0].MainLine2)
        
        })
        fetch("https://student1stop-backend-vercel.vercel.app/Forum",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res=>res.json())
        .then(res1=>{
            // console.log(res1[0]);
            setMainForum(res1[0].student)
        
        })
        fetch("https://student1stop-backend-vercel.vercel.app/CourseAllSubCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            let data = res3.sort((a, b) => {
                return a.sort - b.sort;
            });
            // console.log(data);
            setSubClassCategories(data)
        })
        fetch("https://student1stop-backend-vercel.vercel.app/AllCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            let dat1 = []
            res3.map((res55,c)=>{
                dat1.push(0)
            })
            setfvalue1(dat1)
            let data = res3.sort((a, b) => {
                return a.sort - b.sort;
            });
            setSubCategories(data)        
        }) 

        fetch("https://student1stop-backend-vercel.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res3=>res3.json())
        .then(res4=>{
            let dat = []
            let dat1 = []
            res4.map((res55,c)=>{
                dat.push(res55)
                dat1.push(0)
            })
                        console.log(dat)
            let data = dat.sort((a, b) => {
                return a.sort - b.sort;
            });
            setMainCategories(data)
            setfvalue(dat1)
        })

        // if ( JSON.parse(localStorage.getItem("Admin")) ){
        //     setadminHeader(true)
        //     setuserHeader(false)
        //     setUserDatat([])
        // }
        // else if ( JSON.parse(localStorage.getItem("User"))  ){
        //     setuserHeader(true)
        //     setadminHeader(false)
        //     setUserDatat(JSON.parse(localStorage.getItem("User")))
        // }
        // else{
        //     setadminHeader(false)
        //     setUserDatat([])
        //     setuserHeader(false)

        // }
        const inter = setInterval(()=>{
        //     if ( JSON.parse(localStorage.getItem("Admin")) ){
        //     setadminHeader(true)
        //     setuserHeader(false)
        //     setUserDatat([])
        // }
        // else if ( JSON.parse(localStorage.getItem("User"))  ){
        //     setuserHeader(true)
        //     setadminHeader(false)
        //     setUserDatat(JSON.parse(localStorage.getItem("User")))
        // }
        // else{
        //     setadminHeader(false)
        //     setUserDatat([])
        //     setuserHeader(false)

        // }

            var data1 =  JSON.parse(localStorage.getItem("Cart")) 
         if (data1) {
             setDataPart2(data1)
            //  const data = DataPart2.length  
            //  setDataPart2(data)
            }
         else setDataPart2(0)

         if( JSON.parse(localStorage.getItem("user main")) ) {
             setmainUser(JSON.parse(localStorage.getItem("user main")))
            // document.getElementById("myDiv1234").style.display = "none"
        }
        else{
            setmainUser({})
            // document.getElementById("myDiv1234").style.display = "none"
        }
        //  if ( JSON.parse(localStorage.getItem("Admin")) ){
        //     setadminHeader(true)
        // }
        // if ( JSON.parse(localStorage.getItem("User")) ||  JSON.parse(localStorage.getItem("Admin")) ){
        //     setuserHeader(true)
        // }

        },3000)
        
        return () => clearInterval(inter);
    },[])
  



const headerMenu = () =>{
    if (!menu1){
        localStorage.setItem("head" , JSON.stringify("1000"))
        document.getElementById("my1").classList.remove("offcanvas-menu")
        // const sb = document.getElementById("myDIv")
        // sb.classList.remove("active")
        setmenu1(true)
    }
    else{
        localStorage.removeItem("head")
        document.getElementById("my1").classList.remove("offcanvas-menu")
        // const sb = document.getElementById("myDIv")
        // sb.classList.add("active")
        setmenu1(false) 
    }
    
}




    const SubmitData = (e) =>{
        e.preventDefault()

        fetch("https://student1stop-backend-vercel.vercel.app/SearchProduct",{
                                method: "POST",
                                headers :  {
                                    "Content-Type" : "application/json" , 
                                } ,
                                body : JSON.stringify({
                                    search : search.toLowerCase() ,
   
                                })
                            })
                            .then(res=>res.json())
                            .then((res1)=>{ 
                                console.log(res1)    
                                // localStorage.setItem("SearchData" , JSON.stringify(res1) )
                                // props.history.push(`/shop/search/product-item`)

                                const SearchProductItem = []
                                
                                res1.map((res2,i)=>{
                                    // console.log(res2)
                                    var Name = res2.Product_Name.toLowerCase().split(" ")
                                     var Title = res2.Product_Title.toLowerCase().split(" ")
                                    // console.log(Name , Title)
                                    Name.map((res3,i)=>{

                                        if (res3 === search.toLowerCase().trim()){
                                            SearchProductItem.unshift(res2)
                                        }
                                    })
                                    Title.map((res4,i)=>{
                                        if (res4 === search.toLowerCase().trim()){
                                            SearchProductItem.unshift(res2)
                                        }
                                    })
                                })
                                console.log(SearchProductItem,props)
                                let uniq = [...new Set(SearchProductItem)];

                                localStorage.setItem("SearchData" , JSON.stringify(uniq) )
                                
                                setTimeout(()=>{
                                    history.push("/shop/search/product-item");

                                    // return <Redirect push to="/shop/search/product-item" /> 
                                    // props.history.push(`/shop/search/product-item`)
                                },1000)
                        })
                        .catch(err=>{
                            // swal("There is An Error")                  
                            }) 
    }

    const SubmitData4534 = () =>{

        fetch("https://student1stop-backend-vercel.vercel.app/SearchProduct",{
                                method: "POST",
                                headers :  {
                                    "Content-Type" : "application/json" , 
                                } ,
                                body : JSON.stringify({
                                    search : search.toLowerCase() ,
   
                                })
                            })
                            .then(res=>res.json())
                            .then((res1)=>{ 
                                console.log(res1)    
                                // localStorage.setItem("SearchData" , JSON.stringify(res1) )
                                // props.history.push(`/shop/search/product-item`)

                                const SearchProductItem = []
                                
                                res1.map((res2,i)=>{
                                    // console.log(res2)
                                    var Name = res2.Product_Name.toLowerCase().split(" ")
                                     var Title = res2.Product_Title.toLowerCase().split(" ")
                                    // console.log(Name , Title)
                                    Name.map((res3,i)=>{

                                        if (res3 === search.toLowerCase().trim()){
                                            SearchProductItem.unshift(res2)
                                        }
                                    })
                                    Title.map((res4,i)=>{
                                        if (res4 === search.toLowerCase().trim()){
                                            SearchProductItem.unshift(res2)
                                        }
                                    })
                                })
                                console.log(SearchProductItem,props)
                                let uniq = [...new Set(SearchProductItem)];

                                localStorage.setItem("SearchData" , JSON.stringify(uniq) )
                                
                                setTimeout(()=>{
                                    history.push("/shop/search/product-item");

                                    // return <Redirect push to="/shop/search/product-item" /> 
                                    // props.history.push(`/shop/search/product-item`)
                                },1000)
                        })
                        .catch(err=>{
                            // swal("There is An Error")                  
                            }) 
    }

    const clickLogin = ()=>{
        localStorage.removeItem("user main")
        localStorage.removeItem("User")
        localStorage.removeItem("Cart")
        document.getElementById("myDiv1234").style.display = "none"

        history.push("/user/login")
    }


const effectEnter = () =>{
    const c=  document.getElementById("nav-menu-hover-effect1")
    document.getElementById("desktop-nav1").style.display = "block"
    // console.log(c)

}


const effectCancel = () =>{
    const c1=  document.getElementById("nav-menu-hover-effect1")
    const para = document.getElementById("desktop-nav1");
    document.getElementById("desktop-nav1").style.display = "none"

        para.addEventListener('pointerenter', (event) => {
        document.getElementById("desktop-nav1").style.display = "block"

        // console.log('Pointer entered element');
        });
        para.addEventListener('pointerleave', (event) => {
        document.getElementById("desktop-nav1").style.display = "none"

        // console.log('Pointer entered element');
        });
    // document.getElementById("desktop-nav1").onPointerEnter(()=>{
    //     document.getElementById("desktop-nav1").style.display = "block"

    // })

    // console.log(c1,para)
}

const setStateChangeMenu = ()=>{
    if(!displayMenu){
        setdisplayMenu(true)
        document.getElementById("mobile-site-hide-show11").style.display = "block"
    }
    else{
        setdisplayMenu(false)
        document.getElementById("mobile-site-hide-show11").style.display = "none"
    }

    


}

const setChangeInit = ()=>{
    console.log("edfefef");
    document.getElementById("mobile-site-hide-show11").style.display="none"
    
}

const setChangerItemSize = (e)=>{




    if (fg) setfg(false)
    else setfg(true)

    for (let i = 0; i < 45; i++) {
      if(i === e){
        if(fvalue[e] === 0){
            fvalue[e] = 1
            let f = document.getElementById("changeIcon"+i)
             document.getElementById("changeIcon1"+i).style.transform = "rotate(180deg)"
            // f.innerHTML = "<span style='position : absolute ; right : 10%; '><img src="+img1+" width='30px' height='30px'/></span>"
            
        }
        else {
            fvalue[e] = 0
            let v = document.getElementById("changeIcon"+i)
             document.getElementById("changeIcon1"+i).style.transform = "rotate(0deg)"
            v.innerHTML= ""
            // v.innerHTML =  "<KeyboardArrowDownIcon style={{position : 'absolute' , right : '8%'}} id={changeIcon"+e+"}/>"
         }
      }
    //   else{

    //     if(fvalue[e] === 0){
    //       for (let i = 0; i < 45; i++) {
    //         fvalue[i] = 0
            
    //       }
    //       fvalue[e] = 1 
    //     }
    //     else{
    //         fvalue[e] = 0 
    //     }
    //     }
      
    }
  
  setfvalue(fvalue)
    if (fg) setfg(false)
    else setfg(true)
    // console.log(range123)








    // if(fvalue[i] === 0){

    //     fvalue[i] = 1
    //     if(fg) setfg(true)
    //     else setfg(false) 
    //     setfvalue(fvalue)
    //     if(fg) setfg(true)
    //     else setfg(false) 
    //     setfvalue(fvalue)
    //     console.log(fvalue)
    // }
    // else{
    //     fvalue[i] = 0
    //     if(fg) setfg(true)
    //     else setfg(false) 
    //     setfvalue(fvalue)
    //     if(fg) setfg(true)
    //     else setfg(false) 
    //     setfvalue(fvalue)
    //     console.log(fvalue)

    // }
}

const setChangerItemSize1 = (e)=>{



    if (fg) setfg(false)
    else setfg(true)

    for (let i = 0; i < 45; i++) {
        if(i === e){
          if(fvalue1[e] === 0){
              //   changeIcon12
               document.getElementById("changeIcon12"+i).style.transform = "rotate(180deg)"
              fvalue1[e] = 1
              
            }
            else {
             document.getElementById("changeIcon12"+i).style.transform = "rotate(0deg)"
              fvalue1[e] = 0
           }
        }
    //   else{

    //     if(fvalue1[e] === 0){
    //       for (let i = 0; i < 45; i++) {
    //         fvalue1[i] = 0
            
    //       }
    //       fvalue1[e] = 1 
    //     }
    //     else{
    //         fvalue1[e] = 0 
    //     }
    //     }
      
    }
  
  setfvalue1(fvalue1)
    if (fg) setfg(false)
    else setfg(true)



    // let dis = fvalue
    // if(fvalue1[i] === 0){

    //     fvalue1[i] = 1
    //     if(fg) setfg(true)
    //     else setfg(false) 
    //     setfvalue1(fvalue1)
    //     if(fg) setfg(true)
    //     else setfg(false) 
    //     // setfvalue1(fvalue1)
    //     console.log(fvalue)
    // }
    // else{
    //     fvalue1[i] = 0
    //     if(fg) setfg(true)
    //     else setfg(false) 
    //     setfvalue1(fvalue1)
    //     if(fg) setfg(true)
    //     else setfg(false) 
    //     // setfvalue1(fvalue1)
    //     console.log(fvalue)

    // }
    // setfvalue(dis)
}

const setChangerItem1 = (i,j)=>{
    history.push("/categories/"+i+"/"+j)
    setStateChangeMenu()
}
const setChangebuyit = (a,i,e)=>{
    setbht(i)
    for (let j = 0; j < SubCategories.length; j++) {
        if( SubCategories[j].MainCategories === a){
        console.log(SubCategories[j],i)
        document.getElementById("changer45"+j).style.backgroundColor= "white"
        document.getElementById("changer45"+j).style.color= "black"
    }
}
    for (let j = 0; j < SubCategories.length; j++) {
        if(e === j){
        // console.log(e,j)
        document.getElementById("changer45"+j).style.backgroundColor= "rgb(230, 225, 225)"
        document.getElementById("changer45"+j).style.color= "rgb(36, 163, 181)"
    }
}
}
const setChanger1 = (e)=>{
    document.getElementById("myDiv1234").style.display= "none"
  }
const setChanger134 = ()=>{
    document.getElementById("myDiv1234").style.display= "block"
  }
    const basket = useSelector((state) => state.basket);
        
        return (
            <>
            <div id="myDiv1234">
                {! mainUser.user ?
                <center>
                <button className="fjfklflkdkld" onClick={()=>history.push("/user/login")}>Login or Signup</button>
                <hr />
                    <ul className="ul-li-signup"> 
                        <li onClick={()=>history.push("/help")}>Help & Support</li>
                        {/* <li>Terms & Conditions</li> */}
                        <li onClick={()=>history.push("/shipping-policy")}>Return & Delivery Policy</li>
                        <li onClick={()=>history.push("/privacy-policy")}>Privacy Policy</li>
                        {/* <li>Privacy Policy</li> */}
                    </ul>
                </center>
                :
                
                <ul className="ul-li-signup1"> 
                        <li style={{textTransform : "capitalize" , fontSize : "20px" , padding : "0px"}}><center> Welcome {mainUser.user}</center></li>
                <hr />
                        <li onClick={()=>history.push("/user/dashboard")}><TagFacesSharpIcon style={{marginRight : "20px"}} /> Manage My Account</li>
                        <li onClick={()=>history.push("/shipping-policy")}><LocalMallSharpIcon style={{marginRight : "20px"}} />My Orders</li>
                        <li onClick={()=>history.push("/shipping-policy")}><StarBorderSharpIcon style={{marginRight : "20px"}} />My Reviews</li>
                        <li onClick={()=>clickLogin()}><ExitToAppSharpIcon style={{marginRight : "20px"}} />Logout</li>
                        {/* <li>Privacy Policy</li> */}
                    </ul>
                }
                <ClearIcon id="myDiv34567"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "3%",top : "1%" , zIndex: "2"}} onClick={()=>setChanger1()}/>
            </div> 
                <header className="site-navbar" id="fgjkln" role="banner">
                    <div className="col-12 HeaderMainImp">
                    <center>
                    <marquee direction = "left" scrollamount="10"
                 scrolldelay="20"> {MainLine}</marquee>
                    </center>
                    </div>

            <div className="site-navbar-top">
                <div className="container-fuild" style={{width : "95%" , margin: "auto"}}>
                    <div className="row">
                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-8 logo-menu-width-change">
                        {/* <span className="icon-menu change-header-color33" onClick={()=>setStateChangeMenu()} style={{position :"absolute",paddingTop : "70px"}}></span> */}
                        <MenuIcon  className="icon-menu change-header-color33" onClick={()=>setStateChangeMenu()} style={{position :"absolute",paddingTop : "25px",fontSize : "20px"}}/>
                            {/* <center> */}
                                <Link to="/"> <img src={logo1}  className="logo-img" alt="" style={{objectFit: "contain",paddingRight: "21px"}}/> </Link>
                            {/* </center> */}
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-3 dispaly-bllock-none cart-width-change">

                            <div className="site-top-icons nav-icon-mobile45">
                                                <ul>
                                                {/* <li><Link>
                                                    <PersonIcon  className="icon icon-person change-header-color"  onClick={()=>setChanger134()}  />
                                                    </Link></li> */}
                                                    <li>
                                                        <Link to="/card" className="site-cart">
                                                            <ShoppingCartIcon className="icon icon-shopping_cart change-header-color change-header-color1"/>
                                                            {DataPart2.length > 0? <span className="count">{DataPart2.length > 0? DataPart2.length : ""}</span> : ""}
                                                           
                                                        </Link>
                                                    </li>
                                                    {/* <li><Link to="/signup-user">
                                                    <PersonIcon  className="icon icon-person change-header-color"  />
                                                    </Link></li> */}
                                                    {/* <li><span className="icon-menu change-header-color33" onClick={()=>setStateChangeMenu()} ></span></li> */}

                                                
                                                </ul>
                            </div>                        
                        </div>

                        <div className="col-xl-0 col-lg-0 col-md-1 col-sm-0 fglgiklkkllk" style={{display :  "none"}}>
                            </div>
                        <div className="col-xl-0 col-lg-0 col-md-1 col-sm-0 fglgiklkkllk2" style={{display :  "none"}}>
                            </div>
                        <div className="col-xl-8 col-lg-8 col-md-6 col-sm-12 input-search-mobile site-search-icon input-width-change" style={{marginTop: "9px",width : "400px",border : "3px solid  black" , borderRadius : "47px",height : "50px"}}>
                        {/* <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 input-search-mobile site-search-icon input-width-change" style={{marginTop  : "13px",width : "400px",border : "3px solid  black" , borderRadius : "10px",height : "50px"}}> */}
                            <form className="site-block-top-search" onSubmit={(e)=>SubmitData(e)}>
                                {/* <span className="icon icon-search2"  style={{paddingTop :"20px"}}></span> */}
                                <SearchIcon  className="icon icon-search2 rlkgeglktgkl"  style={{paddingTop :"20px",height : "auto"}}  onClick={()=>SubmitData4534()}/>

                                {/* <SearchIcon className="icon icon-search2"  style={{paddingBottom :"10px"}}/> */}
                                <input type="text" value={search} onChange={(e)=>setSearch(e.target.value)} className="form-control border-0 wsdsd" placeholder=""  style={{paddingTop :"30px"}}/>
                            </form>                        
                        </div>
                        <div className="col-xl-0 col-lg-0 col-md-1 col-sm-0 fglgiklkkllk1" style={{display :  "none"}}>
                            </div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-3 display-noen">
                            <div className="site-top-icons nav-icon-mobile45">
                                                <ul>
                                                    {/* <li><Link>
                                                    <PersonIcon  className="icon icon-person change-header-color" onClick={()=>setChanger134()} />
                                                    </Link></li> */}
                                                    <li>
                                                        <Link to="/card" className="site-cart">
                                                            {/* <span className="icon icon-shopping_cart change-header-color change-header-color1"></span> */}
                                                            <ShoppingCartIcon className="icon icon-shopping_cart change-header-color change-header-color1"/>
                                                            {DataPart2.length ?  <span className="count">{DataPart2.length ? DataPart2.length : ""}</span> : ""}
                                                          
                                                        </Link>
                                                    </li>
                                                    {/* <li><span className="icon-menu change-header-color33" onClick={()=>setStateChangeMenu()} ></span></li> */}

                                                
                                                </ul>
                            </div>                        
                        </div>
                    </div>
                </div>
            </div>


{/* 
                    <div className="site-navbar-top">
                        <div className="container-fuild" style={{width : "90%",margin : "auto"}}>
                            <div className="row align-items-center">

                                 <div className="mb-6 mb-md-0 col-md-6 col-lg-5 col-xl-4 col-sm-12 order-1 order-md-1 text-center logo-design">
                                    <div className="site-logo">
                                        <Link to="/" className="js-logo-clone"> <img src={logo1}  className="logo-img" alt=""/> </Link>
                                    </div>
                                </div>


                                <div className="col-lg-2 col-md-2 col-sm-12 order-2 order-md-2 text-right display-none-icon">
                                    <div className="site-top-icons">
                                        <ul>
                                            <li>
                                                <Link to="/card" className="site-cart">
                                                    <span className="icon icon-shopping_cart change-header-color change-header-color1"></span>
                                                    <span className="count">{DataPart2.length ? DataPart2.length : 0}</span>
                                                </Link>
                                            </li>
                                            <li><Link to="/login"><span className="icon icon-person change-header-color"  ></span></Link></li>
                                            <li><span className="icon-menu change-header-color33" onClick={()=>setStateChangeMenu()} ></span></li>

                                          
                                        </ul>
                                    </div>
                                </div>



                                <div className="col-md-6 col-xl-5 col-lg-5 col-sm-10 order-2 order-md-2 site-search-icon text-left input-search-mobile" style={{width : "400px",border : "3px solid  black" , borderRadius : "10px", margin : "auto"}}>
                                    <form className="site-block-top-search" onSubmit={(e)=>SubmitData(e)}>
                                        <span className="icon icon-search2"></span>
                                        <input type="text" value={search} onChange={(e)=>setSearch(e.target.value)} className="form-control border-0" placeholder="Search Any Product..." />
                                    </form>
                                </div>

                              

                                <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 order-3 order-md-3 text-right nav-icon-mobile">
                                    <div className="site-top-icons">
                                        <ul>
                                            <li>
                                                <Link to="/card" className="site-cart">
                                                    <span className="icon icon-shopping_cart change-header-color change-header-color1"></span>
                                                    <span className="count">{DataPart2.length ? DataPart2.length : 0}</span>
                                                </Link>
                                            </li>
                                            <li><Link to="/login"><span className="icon icon-person change-header-color"  ></span></Link></li>
                                            <li style={{position :  "absolute" , left :  "10%"}}><span className="icon-menu change-header-color33" onClick={()=>setStateChangeMenu()} ></span></li>

                                          
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div> */}
                    <nav className="site-navigation text-right text-md-center" style={{background:"rgb(36, 163, 181)", paddingRight : "-15%"}}>
                        <div className="container">
                       
                            <>
                            {/* {UserDatat && userHeader  ?
                            <center> <p>Welcome <b>{UserDatat.user }</b> </p></center>
                                : ""
                            } */}
                            {/* <ul className="site-menu js-clone-nav">
                                <li className="active">
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about">About</Link>
                                </li>
                                <li className="nav-menu-hover-effect" id="nav-menu-hover-effect1" onPointerEnter={()=>effectEnter()} onPointerLeave={()=>effectCancel()} > <Link >Shop</Link>
                               
                        
                                </li>
                                <li> <Link to="/card">Cart</Link></li>
                                <li><Link to="/checkout">Checkout</Link></li>
                                <li> <Link to="/contact">Contact</Link></li>
                            </ul> */}

                            <ul className="site-menu js-clone-nav">
                            {MainForum
                            ?
                            <li>
                                <Link to={"/student-tutor-forum"} >FIND TUTOR</Link>
                            </li>
                            :
                            ""
                            }
                           
                                {
                                    MainCategories.map((res,i)=>{
                                        {/* if(res.MainCategories === "books" || res.MainCategories === "Uniform"){
                                            return (
                                                <li>
                                                    <Link to={"/main-categories/"+res.MainCategories} >{res.MainCategories}</Link>
                                                </li>
                                        )
                                        }
                                        else{
                                            return (
                                                <li>
                                                    <Link to={"/categories/"+res.MainCategories} >{res.MainCategories}</Link>
                                                </li>
                                        )
                                        } */}
                                        if(res.MainCategories === "books" || res.MainCategories === "Uniform" || res.MainCategories === "Stationery" ){
                                            return (
                                                <li className="erkrkk">
                                                    <Link to={"/main-categories/"+res.MainCategories} >{res.MainCategories}</Link>
                                                    <div className="dropdown123">
                                                        <div style={{display : "flex" , justifyContent : "flex-start"}}>
                                                            <div style={{  width : "40%", textAlign : "left", borderRight : "2px solid #d4d4d4" }}>
                                                                <div>
                                                                    <div style={{display : "flex" ,flexDirection : "column"}}>
                                                                    <p style={{padding   : "10px 30px"}} onClick={()=>history.push("/main-categories/"+res.MainCategories)} ><b style={{fontWeight : "bold"}}> {res.MainCategories }   </b>  </p>
                                                                        {
                                                                            SubCategories.map((res1,j)=>{
                                                                                if(res1.MainCategories === res.MainCategories){
                                                                                    // if(res1.MainCategories === "books") setfvalue("books")
                                                                                    // setfvalue([...new Set([...fvalue , res1.CategoriesName])])
                                                                                    // setfvalue([...fvalue , res1.CategoriesName])
                                                                                    // console.log(res1.CategoriesName)
                                                                                    return(
                                                                                        <>
                                                                                        <p onMouseEnter={()=>setChangebuyit(res.MainCategories,res1.CategoriesName,j)} onClick={()=>props.history.push("/main-categories/"+res.MainCategories+"/"+res1.CategoriesName+"/"+res1.Second_Categories)}  className="fgilklk" id={"changer45"+j}>{res1.CategoriesName}  <span style={{paddingLeft :  "15px", fontSize : "20px",position: "absolute",right: "62%",margin: "-4px"}}><KeyboardArrowDownIcon style={{transform: "rotate(270deg)"}}/></span> </p>
                                                                                        {/* <p onClick={()=>history.push("/main-categories/"+res.MainCategories+"/"+res1.CategoriesName)}  className="fgilklk">{res1.CategoriesName}  <span style={{paddingLeft :  "15px", fontSize : "20px"}}> {"→"} </span></p> */}
                                                                                    
                                                                                        </>
                                                                                    )
                                                                                } 
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                             
                                                                
                                                            </div>
                                                            <div style={{  width : "60%", textAlign : "left"}}>
                                                                <div>
                                                                    <div style={{display : "flex" ,flexDirection : "column" , paddingTop : "5px"}}>
                                                                    {
                                                                        // fvalue.map((res2,k)=>{
                                                                            // SubCategories.map((res3,k)=>{

                                                                            SubClassCategories.map((res1,j)=>{
                                                                                // if(res1.MainCategories === res.MainCategories && res2 === res1.CategoriesName){
                                                                                    if(res1.MainCategories === res.MainCategories  && res1.CategoriesName === bht){
                                                                                        // if(   j+1  <= 15  ){
                                                                                            //    console.log(j+1)
                                                                                    // console.log(res1 )
                                                                                    return(
                                                                                        <>
                                                                                       <a href={"https://student1stop.com/main-categories/"+res.MainCategories+"/"+res1.CategoriesName+"/"+res1.Second_Categories}><p className="fgilklk elfkefkl">{res1.Second_Categories}  </p></a> 
                                                                                       {/* <a href={"https//studentlocal.netlify.app/main-categories/"+res.MainCategories+"/"+res1.CategoriesName+"/"+res1.Second_Categories}><p onClick={()=>history.push("/main-categories/"+res.MainCategories+"/"+res1.CategoriesName+"/"+res1.Second_Categories)}  className="fgilklk elfkefkl">{res1.Second_Categories}  <span style={{paddingLeft :  "15px", fontSize : "15px"}}> {"→"} </span></p></a>  */}

                                                                                    </>
                                                                                    )
                                                                                // }
                                                                                  }
                                                                               
                                                                            })
                                                                        // })
                                                                            
                                                                        }
                                                                    </div>
                                                                </div>
                                                             
                                                          
                                                            </div>
                                                            {/* <div style={{  width : "30%", textAlign : "left"}}>
                                                                <div>
                                                                    <div style={{display : "flex" ,flexDirection : "column", paddingTop : "5px"}}>
                                                                    {
                                                                        // fvalue.map((res2,k)=>{
                                                                            // SubCategories.map((res3,k)=>{

                                                                            SubClassCategories.map((res1,j)=>{
                                                                                // if(res1.MainCategories === res.MainCategories && res2 === res1.CategoriesName){
                                                                                    
                                                                                    if(res1.MainCategories === res.MainCategories  && res1.CategoriesName === bht){
                                                                                        if(    j+16 <= SubClassCategories.length){
                                                                                            console.log(j+16)
                                                                                        // console.log(res1 )
                                                                                    return(
                                                                                        <>
                                                                                        <a href={"https://student1stop.com/main-categories/"+res.MainCategories+"/"+res1.CategoriesName+"/"+res1.Second_Categories}><p className="fgilklk elfkefkl">{res1.Second_Categories}  </p></a>

                                                                                    </>
                                                                                    )
                                                                                }
                                                                            }
                                                                            })
                                                                        // })
                                                                            
                                                                        }
                                                                    </div>
                                                                </div>
                                                             
                                                                
                                                            </div> */}
                                                                                                                                                    {/* <a href={"https://student1stop.com/main-categories/"+res.MainCategories+"/"+res1.CategoriesName+"/"+res1.Second_Categories}><p onClick={()=>history.push("/main-categories/"+res.MainCategories+"/"+res1.CategoriesName+"/"+res1.Second_Categories)}  className="fgilklk elfkefkl">{res1.Second_Categories}  <span style={{paddingLeft :  "15px", fontSize : "15px"}}> {"→"} </span></p> */}

                                                            {/* <div>
                                                                2
                                                            </div>
                                                            <div>
                                                                3
                                                            </div> */}
                                                        </div>
                                                        {/* <li><a href="#">Men's</a></li>
                                                        <li><a href="#">Women's</a></li>
                                                        <li><a href="#">Kid's</a></li> */}
                                                    </div>
                                                </li>
                                        )
                                        }
                                        else{
                                            return (
                                                <li className="erkrkk">
                                                    <Link to={"/categories/"+res.MainCategories} >{res.MainCategories}</Link>
                                                    <div className="dropdown1234">
                                                        <div style={{display : "flex" , justifyContent : "flex-start"}}>
                                                            <div style={{  width : "100%", textAlign : "center" }}>
                                                                <div>
                                                                    <div style={{display : "flex" ,flexDirection : "column"}}>
                                                                    <p style={{padding   : "10px 30px"}} onClick={()=>history.push("/categories/"+res.MainCategories)} ><b style={{fontWeight : "bold"}} > {res.MainCategories }   </b>  </p>
                                                                        {
                                                                            SubCategories.map((res1,j)=>{
                                                                                if(res1.MainCategories === res.MainCategories){
                                                                                    return(
                                                                                        <>
                                                                                        <p onClick={()=>history.push("/categories/"+res.MainCategories+"/"+res1.CategoriesName)}  className="fgilklk">{res1.CategoriesName}  </p>
                                                                                       
                                                                                        </>
                                                                                    )
                                                                                } 
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                             
                                                                
                                                            </div>
                                                            
                                                            
                                                            
                                                        </div>
                                                       
                                                    </div>
                                                </li>
                                        )
                                        }
                                        
                                    })
                                }
                                {/* <li className="active">
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about">About</Link>
                                </li>
                                <li className="nav-menu-hover-effect" id="nav-menu-hover-effect1" onPointerEnter={()=>effectEnter()} onPointerLeave={()=>effectCancel()} > <Link >Shop</Link>
                               
                        
                                </li>
                                <li> <Link to="/card">Cart</Link></li>
                                <li><Link to="/checkout">Checkout</Link></li>
                                <li> <Link to="/contact">Contact</Link></li>*/}
                                {MainForum
                            ?
                                <li>

                                <Link to={"/teacher-medium-forum"} >TUTOR REGISTRATION</Link>
                            </li>
                            :""}
                            </ul> 
                            </>
                        
                            
                        </div>
                    </nav>
                    {/* <div className="desktop-nav">
                         <ul className="desktop-nav-drop">
                                    <li>dfiiubn </li>
                                    <li> fdvkjn</li>
                                    <li> doikn</li>
                        </ul>
                    </div> */}

                    <div className="mobile-site-hide-show" id="mobile-site-hide-show11"  style={{background:"white" ,display : "none" ,position: "absolute",top: "0px",width:" 93%",height: "500vh"}}>
                        <center style={{color :"white",padding : "14px 0px" , fontWeight : "bold" , fontSize : "25px",background:"rgb(36, 163, 181)"}}>
                            <h4>Menu
                            <ClearIcon onClick={()=>setStateChangeMenu()}  style={{color : "white",position : "absolute" ,right : "10%"}}  />

                            {/* <span className="icon icon-cancel" style={{color : "black",position : "absolute" ,right : "8%"}}  onClick={()=>setStateChangeMenu()}></span> */}
                            </h4>
                        </center>
                        <ul className="mobile-head-menu">
                        {MainForum
                            ?
                            <li>
                                <Link  className="mobile-nav-border" style={{textTransform : "Uppercase"}} to={"/student-tutor-forum"} >FIND TUTOR</Link>
                            </li>
                            :""}
                                {
                                    MainCategories.map((res,i)=>{
                                        if(res.MainCategories === "books" || res.MainCategories === "Uniform" || res.MainCategories === "Stationery"  ){
                                            return (
                                                <li >
                                                    <Link    className="mobile-nav-border" style={{textTransform : "Uppercase"}} onClick={()=>setChangerItemSize(i)} >{res.MainCategories}<span style={{color : "black" ,paddingLeft :  "125px", fontSize : "20px", fontWeight : "bold" ,transform: "rotate(180deg)"}} id={"changeIcon"+i}> </span></Link>
                                                    <KeyboardArrowDownIcon style={{color : "black" ,position : "absolute" , right : "8%"}} id={"changeIcon1"+i}  onClick={()=>setChangerItemSize(i)} />

                                                    {
                                                        SubCategories.map((res1,j)=>{
                                                            if(res1.MainCategories === res.MainCategories){
                                                                return(
                                                                    <>
                                                                    <li   style={{display : fvalue[i] ? "block" :  "none"}}  onClick={()=>setChangerItemSize1(j)} > <b className="fgilklk1" > {res1.CategoriesName}  <span style={{color : "black" ,position : "absolute" , right : "30px" , paddingLeft :  "5px", fontSize : "20px", fontWeight : "bold"}} onClick={()=>setChangerItemSize1(j)}> <KeyboardArrowDownIcon id={"changeIcon12"+j}   onClick={()=>setChangerItemSize1(j)}/> </span></b></li>
                                                                    {/* <li onClick={()=>history.push("/main-categories/"+res.MainCategories+"/"+res1.CategoriesName)}  > <b className="fgilklk1"> {res1.CategoriesName}  <span style={{paddingLeft :  "15px", fontSize : "20px"}}> {"→"} </span></b></li> */}
                                                                    {
                                                                      SubClassCategories.map((res2,k)=>{
                                                                        if( res2.CategoriesName === res1.CategoriesName){
                                                                            // console.log(res1 )
                                                                            return(
                                                                                <>
                                                                                <a  style={{display : fvalue1[j] ? "block" :  "none"}} href={"https://student1stop.com/main-categories/"+res.MainCategories+"/"+res1.CategoriesName+"/"+res2.Second_Categories}><p className="fgilklk elfkefkl dfidfpfdfp">{res2.Second_Categories} </p></a> 

                                                                            </>
                                                                            )
                                                                        }
                                                                    })  
                                                                    }
                                                                    </>
                                                                )
                                                            } 
                                                        })
                                                    }
                                                </li>
                                        )
                                        }
                                        else{
                                            return (
                                                <li>
                                                    <Link  onClick={()=>setChangerItemSize(i)} className="mobile-nav-border" style={{textTransform : "Uppercase"}}>{res.MainCategories}  <span style={{paddingLeft :  "15px", fontSize : "20px", fontWeight : "bold" ,transform: "rotate(180deg)"}} id={"changeIcon"+i} >  </span></Link><KeyboardArrowDownIcon style={{color : "black" ,position : "absolute" , right : "8%"}}  id={"changeIcon1"+i}  onClick={()=>setChangerItemSize(i)} />
                                                    {
                                                    SubCategories.map((res1,j)=>{
                                                        if(res1.MainCategories === res.MainCategories){
                                                            return(
                                                                <>
                                                                   <li    style={{display : fvalue[i] ? "block" :  "none"}} onClick={()=>setChangerItem1(res.MainCategories,res1.CategoriesName)} > <b className="fgilklk1"> {res1.CategoriesName}  <span style={{paddingLeft :  "15px", fontSize : "20px", fontWeight : "bold" ,transform: "rotate(180deg)"}}></span></b></li>
                                                                   {/* <li onClick={()=>history.push("/categoriess/"+res.MainCategories+"/"+res1.CategoriesName)} style={{display : fvalue[i] ? "block" :  "none"}} > <b className="fgilklk1"> {res1.CategoriesName}  <span style={{paddingLeft :  "15px", fontSize : "20px"}}> {"→"} </span></b></li> */}

                                                                {/* <p onClick={()=>history.push()}  className="fgilklk1">{res1.CategoriesName}  <span style={{paddingLeft :  "15px", fontSize : "20px"}}> {"→"} </span></p> */}
                                                                
                                                                </>
                                                            )
                                                        } 
                                                    })
                                                    }
                                                </li>
                                        )
                                        }
                                        
                                        {/* return (
                                                <li>
                                                    <Link onClick={()=>setChangeInit()} to={"/categories/"+res.MainCategories} className="mobile-nav-border" style={{textTransform : "Uppercase"}}>{res.MainCategories}</Link>
                                                </li>
                                        ) */}
                                        
                                    })
                                }
                                {MainForum
                            ?
                                <li>
                                    <Link  className="mobile-nav-border" style={{textTransform : "Uppercase"}} to={"/teacher-medium-forum"} >TUTOR REGISTRATION</Link>
                                </li>
                                :""}
                            </ul> 
                         {/* <ul  className="mobile-head-menu" >
                                <li className="part2-nav"> 
                                    <Link className="mobile-nav-border" to="/shop">Shop</Link>
                                    <ul className="nested-drop-nav">
                                        <li>1</li>
                                        <li>2</li>
                                        <li>3</li>
                                        <li>4</li>
                                    </ul>
                               </li>
                                <li className="active">
                                    <Link className="mobile-nav-border" to="/" >Home</Link>
                                </li>
                                <li>
                                    <Link className="mobile-nav-border" to="/about"> About</Link>
                                </li>
                                <li>
                                    <Link className="mobile-nav-border" to="/card">Cart</Link>
                                 </li>
                                <li>
                                    <Link className="mobile-nav-border" to="/checkout">Checkout</Link>
                                </li>
                                <li> 
                                    <Link className="mobile-nav-border" to="/contact">Contact</Link>
                                </li>
                        </ul> */}
                    </div>
                    {/* <div className="mobile-site-hide-show" id="mobile-site-hide-show11">
                        <center style={{padding : "30px 0px" , fontWeight : "bold" , fontSize : "25px"}}>
                            <h4>Menu<span className="icon icon-cancel" style={{position : "absolute" ,right : "8%"}}  onClick={()=>setStateChangeMenu()}></span></h4>
                        </center>
                         <ul  className="mobile-head-menu" >
                                <li className="part2-nav"> 
                                    <Link className="mobile-nav-border" to="/shop">Shop</Link>
                                    <ul className="nested-drop-nav">
                                        <li>1</li>
                                        <li>2</li>
                                        <li>3</li>
                                        <li>4</li>
                                    </ul>
                               </li>
                                <li className="active">
                                    <Link className="mobile-nav-border" to="/" >Home</Link>
                                </li>
                                <li>
                                    <Link className="mobile-nav-border" to="/about"> About</Link>
                                </li>
                                <li>
                                    <Link className="mobile-nav-border" to="/card">Cart</Link>
                                 </li>
                                <li>
                                    <Link className="mobile-nav-border" to="/checkout">Checkout</Link>
                                </li>
                                <li> 
                                    <Link className="mobile-nav-border" to="/contact">Contact</Link>
                                </li>
                        </ul>
                    </div> */}
                   

                </header>
                {/* <div>
                    h1
                </div> */}
                {/* <div className="mobile-header-1 d-md-none">
                    <ul className="uli">
                                <li className="active">
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about">About</Link>
                                </li>
                                <li> <Link to="/shop">Shop</Link></li>
                                <li><Link to="/checkout">Checkout</Link></li>
                                <li> <Link to="/contact">Contact</Link></li>
                            </ul>
                </div> */}
            </>
        )
}

export default Header;