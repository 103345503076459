import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"

import Loader from "react-loader-spinner";

// import blockCover from '../assets/images/hero_1.jpg';
// import categ from '../assets/images/cate.jpeg';
// import women from '../assets/images/women.jpg';
// import children from '../assets/images/children.jpg';
// import men from '../assets/images/men.jpg';

// import cloth_1 from '../assets/images/cloth_1.jpg';
// import shoe_1 from '../assets/images/shoe_1.jpg';
// import cloth_2 from '../assets/images/cloth_2.jpg';
// import cloth_3 from '../assets/images/cloth_3.jpg';
// import blog_1 from '../assets/images/blog_1.jpg';
// import part_1 from '../assets/images/part1.jpg';
// import part_2 from '../assets/images/part2.jpg';

// import par1 from '../assets/images/par1.jpg';
// import par2 from '../assets/images/par2.jpg';
// import par3 from '../assets/images/par3.jpg';
// import par4 from '../assets/images/par4.jpg';
// import par5 from '../assets/images/par5.jpg';
// import par6 from '../assets/images/par6.jpg';
// import back from '../assets/images/back.jpg';
// import bigPhoto from "../assets/images/bigPhoto.jpg"
// import u5 from '../assets/images/u5.jfif';

// import Ban1 from "../assets/images/banner1.jpg"
// import Ban2 from "../assets/images/banner2.jpg"
// import Ban3 from "../assets/images/banner3.jpg"


import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import ReplayIcon from '@material-ui/icons/Replay';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import swal from 'sweetalert';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};






const Home = ( props ) => {
    const [Categories , setCategories] = useState([])
    const [Categories1 , setCategories1] = useState([])
    const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)

    const [product1 , setproduct1] = useState([])
    const [UserDatat , setUserDatat] = useState({})
    const [userHeader , setuserHeader] = useState(false)
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [ArrivalPhoto , setArrivalPhoto] = useState([])
    const [BannerPhoto , setBannerPhoto] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    useEffect(() =>{

        window.scrollTo(0, 0)

        fetch("https://student1stop-backend-vercel.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            console.log(res3);
            // setMainCatogories(res3)
            

            let dat = []
            res3.map((res55,c)=>{
                dat.push(res55)
            })
            // console.log(SubClassCategories,asse)
            console.log(dat)
            let data = dat.sort((a, b) => {
                return a.sort - b.sort;
            });
            setMainCatogories(data)
            
        })
        

        fetch("https://student1stop-backend-vercel.vercel.app/slider-photo",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res=>res.json())
        .then(res1=>{
            console.log(res1[0]);
            setSliderPhoto(res1)
        
        })

        fetch("https://student1stop-backend-vercel.vercel.app/BannerPhoto",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res22=>res22.json())
        .then(res222=>{
            console.log(res222[0]);
            setBannerPhoto(res222)
        
        })

        fetch("https://student1stop-backend-vercel.vercel.app/arrival-photo",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            console.log(res3);
            setArrivalPhoto(res3)
        
        })




        localStorage.removeItem("SearchData")
        localStorage.removeItem("Data")
        localStorage.removeItem("CartPrice")
        localStorage.removeItem("CateProduct")

        // if ( JSON.parse(localStorage.getItem("User"))  ){
        //     setuserHeader(true)
        //     setUserDatat(JSON.parse(localStorage.getItem("User")))
        //     const cartUser1 = JSON.parse(localStorage.getItem("Cart"))
        //     if (cartUser1 ){
        // fetch("https://student1stop-backend-vercel.vercel.app/user-cart-add",{
        //                         method: "POST",
        //                         headers :  {
        //                             "Content-Type" : "application/json" , 
        //                         } ,
        //                         body : JSON.stringify({
        //                             cart : cartUser1 ,
        //                             user : JSON.parse(localStorage.getItem("User")) 
        //                         })
        //                     })
        //                     .then(res=>res.json())
        //                     .then((res1)=>{ 
        //                         console.log(res1);
        //                     })

        //     }
        // }

        fetch("https://student1stop-backend-vercel.vercel.app/AllProduct",{
       method: "GET",
        headers :  {
        "Content-Type" : "application/json" , 
    }
   })
   .then(res5=>res5.json())
   .then(res6=>{
    //    console.log(res6);
    const pro = []
    res6.map((res7,i)=>{
        //    console.log(res7.Product_Popular );
        if ( res7.Arrival){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro.push(res7)
        }
    })
    // setproduct(pro.reverse())
    setproduct(pro.slice(0,3))
    setdis(true)
   })
    
//     fetch("/AllCategories",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res=>res.json())
//    .then(res1=>{
//     setCategories(res1)
   
//    })

    
//     fetch("/AllHomomethtic",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res3=>res3.json())
//    .then(res4=>{
//     setHomo(res4)
//     // console.log(res4);
//    })

 },[])

 const savethedetailproduct = (data) =>{
    localStorage.setItem("Data" , JSON.stringify(data) )
    console.log(data);
 }
 
 
 const cate =(Categories) =>{
     var Cat1 = Categories.split(" ").join("-")
     
     localStorage.setItem("Cate" , JSON.stringify(Categories) )
     
     setTimeout(()=>{
            props.history.push(`/shop/categories/${Cat1}`)
        },1500)
    }
    
    
    const settoBags = (data111) =>{
    //    localStorage.setItem("Data" , JSON.stringify(data) )
       console.log(data111);


       if(data111.ProductCreater !== "60a3c644e4520a12c80a6f52"){
        console.log("yes ");
        fetch("https://student1stop-backend-vercel.vercel.app/ChangePercentageInOrder/"+data111.ProductCreater,{
                            method: "POST",
                            headers :  {
                                "Content-Type" : "application/json" , 
                            }
                        })
                        .then(res=>res.json())
                        .then((res1)=>{ 
                            console.log(res1);
                            // if(!res1 === null){
document.getElementById("myDi").style.visibility = "visible"
    setTimeout(()=>{
        document.getElementById("myDi").style.visibility = "hidden"

    },1000)
    if (data111.Product_Price_Discounted === null){
        const data =  {...data111 ,
                    
                        Percentage : data111.Percentage === 0 ? res1.Percentage : data111.Percentage,
                    DeliveryStatus : "Pending",
                     Pieces :1,
                     Total_Product_Price  : data111.Product_Price *1}
    var data1 = JSON.parse(localStorage.getItem("Cart")) 
    if (data1){
        var data3 = data1.map((item) => {
            if(item._id === data111._id){
                console.log("double");
;                   localStorage.setItem("double",JSON.stringify(true))
                return {...item,
                    Pieces :1+ item.Pieces,
                    Total_Product_Price  : (data111.Product_Price *1)+ item.Total_Product_Price}
            }
            else{
                console.log("double not match");
                return item
            }

        })
        var data5 =  JSON.parse(localStorage.getItem("double")) 
        // console.log(DataPart2.length, data3.length,data5);
        var data10 =  JSON.parse(localStorage.getItem("Cart")) 

        if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )
         
        }
        else{
            console.log("Differet");
            var data2 = [...data1 , data]
        
       localStorage.setItem("Cart" , JSON.stringify(data2) )
        }
    }
    else{
        console.log("1");
        localStorage.setItem("Cart" , JSON.stringify([data]) )
      
    }

    }
    else if (data111.Product_Price_Discounted){
        const data =  {...data111 ,
                    
                        Percentage : data111.Percentage === 0 ? res1.Percentage : data111.Percentage,
                    DeliveryStatus : "Pending",
                     Pieces :1,
                     Total_Product_Price  : data111.Product_Price_Discounted *1}
    var data1 = JSON.parse(localStorage.getItem("Cart")) 
    if (data1){
        var data3 = data1.map((item) => {
            if(item._id === data111._id){
                console.log("double");
;                   localStorage.setItem("double",JSON.stringify(true))
                return {...item,
                    Pieces :1+ item.Pieces,
                    Total_Product_Price  : (data111.Product_Price_Discounted *1)+ item.Total_Product_Price}
            }
            else{
                console.log("double not match");
                return item
            }

        })
        var data5 =  JSON.parse(localStorage.getItem("double")) 
        // console.log(DataPart2.length, data3.length,data5);
        var data10 =  JSON.parse(localStorage.getItem("Cart")) 

        if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )
         
        }
        else{
            console.log("Differet");
            var data2 = [...data1 , data]
        
       localStorage.setItem("Cart" , JSON.stringify(data2) )
        }
    }
    else{
        console.log("1");
        localStorage.setItem("Cart" , JSON.stringify([data]) )
      
    }

    }
    else if (data111.Size_Discounted[0]){
        const data =  {...data111 ,
            Percentage : data111.Percentage === 0 ? res1.Percentage : data111.Percentage,
            DeliveryStatus : "Pending",
            Pieces :1,
            Size : data111.Size[0],
            Product_Price_Discounted : data111.Size_Discounted[0],
            Total_Product_Price  : data111.Size_Discounted[0] *1}
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data111._id &&  item.Size === data111.Size[0]){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
                    return {...item,
                        Pieces :1+ item.Pieces,
                        Total_Product_Price  : (data111.Size_Discounted[0] *1)+ item.Total_Product_Price}
                }
                else{
                    console.log("double not match");
                    return item
                }

            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            // console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 

            if(data10.length=== data3.length && data5){
                console.log("double remove");
                localStorage.removeItem("double")
                localStorage.setItem("Cart" , JSON.stringify(data3) )
            
            }
            else{
                console.log("Differet");
                var data2 = [...data1 , data]
            
            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )

            }
    }


    else{




                        const data =  {...data111 ,
                                Percentage : data111.Percentage === 0 ? res1.Percentage : data111.Percentage,
                            DeliveryStatus : "Pending",
                            Pieces :1,
                            Size : data111.Size[0],
                            Product_Price_Discounted : data111.Price[0],
                            Total_Product_Price  : data111.Price[0] *1}
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data111._id &&  item.Size === data111.Size[0]){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
                    return {...item,
                        Pieces :1+ item.Pieces,
                        Total_Product_Price  : (data111.Price[0] *1)+ item.Total_Product_Price}
                }
                else{
                    console.log("double not match");
                    return item
                }

            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            // console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 

            if(data10.length=== data3.length && data5){
                console.log("double remove");
                localStorage.removeItem("double")
                localStorage.setItem("Cart" , JSON.stringify(data3) )
              
            }
            else{
                console.log("Differet");
                var data2 = [...data1 , data111]
             
            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )
      
            }


    }


  })
    

}













else{
  
                document.getElementById("myDi").style.visibility = "visible"
                setTimeout(()=>{
                document.getElementById("myDi").style.visibility = "hidden"

                },1000)
                // console.log(productDetail);
                if (data111.Product_Price_Discounted === null){
                const data =  {...data111 ,
                    Percentage : 0,
                    DeliveryStatus : "Pending",
                    Pieces :1,
                    Total_Product_Price  : data111.Product_Price *1}
                var data1 = JSON.parse(localStorage.getItem("Cart")) 
                console.log(  data1);
                if (data1){
                    console.log(  data111._id);
                var data3 = data1.map((item) => {
                    console.log(item._id , data111._id);
                    if(item._id === data111._id ){
                        console.log("double");
                ;                   localStorage.setItem("double",JSON.stringify(true))
                return {...item,
                    Pieces :1+ item.Pieces,
                    Total_Product_Price  : (data111.Product_Price *1)+ item.Total_Product_Price}
                }
                else{
                console.log("double not match");
                return item
                }

                })
                var data5 =  JSON.parse(localStorage.getItem("double")) 
                // console.log(DataPart2.length, data3.length,data5);
                var data10 =  JSON.parse(localStorage.getItem("Cart")) 

                if(data10.length=== data3.length && data5){
                console.log("double remove");
                localStorage.removeItem("double")
                localStorage.setItem("Cart" , JSON.stringify(data3) )

                }
                else{
                console.log("Differet");
                var data2 = [...data1 , data111]

                localStorage.setItem("Cart" , JSON.stringify(data2) )
                }
                }
                else{
                console.log("1");
                localStorage.setItem("Cart" , JSON.stringify([data]) )

                }

                }
                else if (data111.Product_Price_Discounted){
                const data =  {...data111 ,
                    Percentage : 0,
                    DeliveryStatus : "Pending",
                    Pieces :1,
                    Total_Product_Price  : data111.Product_Price_Discounted *1}
                var data1 = JSON.parse(localStorage.getItem("Cart")) 
                console.log(  data1);
                if (data1){
                    console.log(  data111._id);
                var data3 = data1.map((item) => {
                    console.log(item._id , data111._id);
                    if(item._id === data111._id ){
                        console.log("double");
                ;                   localStorage.setItem("double",JSON.stringify(true))
                return {...item,
                    Pieces :1+ item.Pieces,
                    Total_Product_Price  : (data111.Product_Price_Discounted *1)+ item.Total_Product_Price}
                }
                else{
                console.log("double not match");
                return item
                }

                })
                var data5 =  JSON.parse(localStorage.getItem("double")) 
                // console.log(DataPart2.length, data3.length,data5);
                var data10 =  JSON.parse(localStorage.getItem("Cart")) 

                if(data10.length=== data3.length && data5){
                console.log("double remove");
                localStorage.removeItem("double")
                localStorage.setItem("Cart" , JSON.stringify(data3) )

                }
                else{
                console.log("Differet");
                var data2 = [...data1 , data111]

                localStorage.setItem("Cart" , JSON.stringify(data2) )
                }
                }
                else{
                console.log("1");
                localStorage.setItem("Cart" , JSON.stringify([data]) )

                }

                }
                else if (data111.Size_Discounted[0]){
                    const data =  {...data111 ,
                        Percentage : 0,
                        DeliveryStatus : "Pending",
                        Pieces :1,
                        Size : data111.Size[0],
                        Product_Price_Discounted : data111.Size_Discounted[0],
                        Total_Product_Price  : data111.Size_Discounted[0] *1}
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data111._id &&  item.Size === data111.Size[0]){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
                return {...item,
                    Pieces :1+ item.Pieces,
                    Total_Product_Price  : (data111.Size_Discounted[0] *1)+ item.Total_Product_Price}
            }
            else{
                console.log("double not match");
                return item
            }

            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            // console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 

            if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )

            }
            else{
            console.log("Differet");
            var data2 = [...data1 , data111]

            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )

            }

                }
                else{




                        const data =  {...data111 ,
                            Percentage : 0,
                            DeliveryStatus : "Pending",
                            Pieces :1,
                            Size : data111.Size[0],
                            Product_Price_Discounted : data111.Price[0],
                            Total_Product_Price  : data111.Price[0] *1}
                var data1 = JSON.parse(localStorage.getItem("Cart")) 
                if (data1){
                var data3 = data1.map((item) => {
                    if(item._id === data111._id &&  item.Size === data111.Size[0]){
                        console.log("double");
                ;                   localStorage.setItem("double",JSON.stringify(true))
                    return {...item,
                        Pieces :1+ item.Pieces,
                        Total_Product_Price  : (data111.Price[0] *1)+ item.Total_Product_Price}
                }
                else{
                    console.log("double not match");
                    return item
                }

                })
                var data5 =  JSON.parse(localStorage.getItem("double")) 
                // console.log(DataPart2.length, data3.length,data5);
                var data10 =  JSON.parse(localStorage.getItem("Cart")) 

                if(data10.length=== data3.length && data5){
                console.log("double remove");
                localStorage.removeItem("double")
                localStorage.setItem("Cart" , JSON.stringify(data3) )

                }
                else{
                console.log("Differet");
                var data2 = [...data1 , data111]

                localStorage.setItem("Cart" , JSON.stringify(data2) )
                }
                }
                else{
                console.log("1");
                localStorage.setItem("Cart" , JSON.stringify([data]) )

                }


                }


                    }


    }

const setsellyourad = () =>{

    if(JSON.parse(localStorage.getItem("user main")))
    props.history.push("/post-your-ad")
    else{
        swal("Login is Must to Post Your Ad")
    }
}
        return (
            
            <div className="site-wrap">
<center>
            <div className="pop-up-1 dis-off" id="myDi">
                <h1>Product Updated in Cart</h1>
            </div> 
        </center>


        {/* <div className='fdgrg' style={{position: "fixed",bottom: "43px",left: "45%",zIndex: "4"}}>
             <button className="button11" type="button" class="btn btn-dark" style={{width : "140px",height:"50px",borderRadius : "10px" , fontWeight :  "bold", padding : "14px 30px" ,fontSize : "11px" , margin : "0px 15px"}}  onClick={()=>setsellyourad()}>SELL</button>
</div> */}

{MainCatogories.length === 0?
   <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={10000} //3 secs
        className="loader-1"
        // style={{position : "fixed",    left: "35%",top:"20%",zIndex: "9999"}}
      /> 
      :""
}
                {/* <div className="site-blocks-cover" style={{ backgroundImage: `url(${blockCover})` }} data-aos="fade">
                    <div className="container">
                        <div className="row align-items-start align-items-md-center justify-content-end">
                            <div className="col-md-5 text-center text-md-left pt-5 pt-md-0">
                                <h1 className="mb-2">Finding Your Perfect Shoes</h1>
                                <div className="intro-text text-center text-md-left">
                                    <p className="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus at iaculis quam. Integer accumsan tincidunt fringilla. </p>
                                    <p>
                                        <Link to='/shop'><div href="#" className="btn btn-sm btn-primary">Shop Now</div></Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <section>
                    <div className="container-fuild">
                        <div className="col-xl-12 col-lg-12 col-md-12" style={{height : "   ",width : "100%", margin  : "0px",padding: "0px"}} >
                                        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" data-interval="2000" auto-play="true">
                                        <ol class="carousel-indicators">
                                            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                                            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                                        </ol>
                                            <div class="carousel-inner">
                                            {
                                                SliderPhoto.length > 0 && SliderPhoto.map((res,i)=>{
                                                    console.log(res)
                                                        return (
                                                             <>
                                                            <div class="carousel-item active" >
                                                                <img class="d-block w-100 jkkukk" src={res.photo1} alt="First slide"  style={{}}/>
                                                            </div>
                                                       
                                                                <div class="carousel-item" >
                                                                    <img class="d-block w-100 jkkukk" src={res.photo2} alt="Second slide"  style={{}}/>
                                                                </div>
                                                                <div class="carousel-item" >
                                                                    <img class="d-block w-100 jkkukk" src={res.photo3} alt="Third slide"  style={{}}/>
                                                                </div>
                                                                <div class="carousel-item" >
                                                                    <img class="d-block w-100 jkkukk" src={res.photo4} alt="Fourth slide"  style={{}}/>
                                                                </div>
                                                            </>
                                                        )
                                                })
                                            }
                                                {/* <div class="carousel-item active" >
                                                    <img class="d-block w-100" src={Ban1} alt="First slide"  style={{height:"300px"}}/>
                                                </div>
                                                <div class="carousel-item">
                                                    <img class="d-block w-100" src={Ban2} alt="Second slide"   style={{height:"300px"}}/>
                                                </div>
                                                <div class="carousel-item">
                                                    <img class="d-block w-100" src={Ban3} alt="Third slide"   style={{height:"300px"}}/>
                                                </div>
                                                <div class="carousel-item">
                                                    <img class="d-block w-100" src={blog_1} alt="Fourth slide"   style={{height:"300px"}}/>
                                                </div> */}
                                            </div>
                                            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span class="sr-only">Previous</span>
                                            </a>
                                            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span class="sr-only">Next</span>
                                            </a>
                                        </div>
                        </div>
                    </div>
                </section>



                <section>
                    <div className="container-fuild" style={{margin : "70px auto" , width : "95%"}}>
                            <div className="row justify-content-center" style={{margin: "auto"}}>
                                <div className="col-12 text-center">
                                    <h2 style={{fontSize: "30px",color : "black"}} > <b>Discover Our Collections</b> </h2>
                                    <center>
                                        <p style={{width : "280px" , border : "0.5px solid grey" }}></p>
                                    </center>
                                </div>
                                {
                                    MainCatogories.map((res,i)=>{
                                        if(res.MainCategories === "books"|| res.MainCategories === "Uniform"|| res.MainCategories === "Stationery"){
                                            return (
                                                <div className="arrival1">
                                                    <Link to={"/main-categories/"+res.MainCategories}><img src={res.photo} alt="" className="arrival-Photo11"/></Link>
                                                </div>
                                            ) 
                                        }
                                        else{
                                            return (
                                                <div className="arrival1">
                                                    <Link to={"/categories/"+res.MainCategories}><img src={res.photo} alt="" className="arrival-Photo11"/></Link>
                                                </div>
                                            )    
                                        }
                                        
                                    })
                                } 
                                        {/* <div className="arrival1">
                                            <Link to="/categories/books"><img src={par1} alt="" className="arrival-Photo11"/></Link>
                                        </div>
                                        <div className="arrival1" >
                                            <Link to="/categories/shoes"><img src={par2} alt="" className="arrival-Photo11"/></Link>
                                        </div>
                                        <div className="arrival1" >
                                            <Link to="/categories/bags"><img src={par3} alt="" className="arrival-Photo11"/></Link>
                                        </div>
                                            
                                        <div className="arrival1">
                                            <img src={par4} alt="" className="arrival-Photo11"/>
                                        </div>
                                        <div className="arrival1" >
                                            <img src={par5} alt="" className="arrival-Photo11"/>
                                        </div>
                                        <div className="arrival1" >
                                        <Link to="/categories/Uniform"><img src={u5} alt="" className="arrival-Photo11"/></Link>
                                        </div> */}
                            </div>
                               
                            </div>
                </section>








                <section style={{width : "94%"}}>
                    <div className="container-fuild" style={{margin : "70px auto 20px auto"  , width : "77%"}}>
                            <div className="row justify-content-center">
                                <div className="text-center col-12">
                                    <h2 style={{fontSize: "30px",color : "black"}} > <b>New Arrivals</b> </h2>
                                    <center>
                                        <p style={{width : "220px" , border : "0.5px solid grey" }}></p>
                                    </center>
                                </div> 
                                {/* {
                                    ArrivalPhoto.length > 0 && ArrivalPhoto.map((res,i)=>{
                                        return (
                                            <>
                                            <Link to="/arrival/product">
                                                <div className="arrival">
                                                    <img src={res.photo1} alt="" className="arrival-Photo"/>
                                                </div> 
                                            </Link>
                                            <Link to="/arrival/product">
                                                <div className="arrival">
                                                    <img src={res.photo2} alt="" className="arrival-Photo"/>
                                                </div> 
                                            </Link>
                                            </>
                                        )
                                    })
                                } */}






                                <div className="row mb-5">
                                   {dis ?
                                    product.length > 0 ?
                                    product.length === 3 ?
                                    product.map((res,i)=>{
                                        if(res.Product_Catagories === "books" || res.Product_Catagories === "Uniform"  || res.Product_Catagories === "Stationery" ){
                                          return (
                                            <div className="col-xl-4 col-sm-12 col-lg-6 mb-12" data-aos="fade-up" style={{marginBottom : "30px"}}>
                                                <div className="block-4 text-center hover-product-card" style={{width: "300px"}}>
                                                <span style={{margin : "10px 4px" , color : "red", fontSize : "20px" , fontWeight : "bold",textAlign : "left"}}>
                                                {res.Size_Discounted[0] ?
                                                    <span>{(100 - ( res.Size_Discounted[0]*100) /  res.Price[0]).toFixed(2)}  % off</span>
                                                    : 
                                                res.Product_Price_Discounted ?
                                                    <span>{(100 - (res.Product_Price_Discounted *100) /  res.Product_Price).toFixed(2)}  % off</span>
                                                    : ""
                                                }
                                                </span>
                                                    <figure className="block-4-image">
                                                    {/* <Link to='/detail'> */}
                                                    <Link to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/"+res._id+"/"+res.Product_Name} ><img src={res.Product_Image_Upload}  style={{height : "210px" , width : "200px" }}  alt="Image placeholder" className="img-fluid" /></Link>
                                                    {/* </Link> */}
                                                    </figure>
                                                    {/* <div className="block-4-text p-4" onMouseEnter={()=>ViewAtDetails()} onMouseLeave={()=>NoViewAtDetails()} > */}
                                                    <div className="block-4-text p-4">
                                                        <h3><Link to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/"+res._id+"/"+res.Product_Name} > {res.Product_Name}</Link></h3>
                                                        <p className="mb-0">{res.Product_Title}</p>
                                                        {
                                                            res.Size_Discounted[0] ?
                                                            <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
                                                            <p  style={{color  : "black"  }}>Rs: {res.Size_Discounted[0]}</p>
                                                            <div style={{width : "10px"}}></div>
                                                            <p style={{color  : "grey"  }}> <b><s>Rs: {res.Price[0]}</s></b>  </p>
                                                            <div style={{width : "10px"}}></div>
                                                            <p  style={{color  : "black"  }}>Size : {res.Size[0]}</p>
                                                            </div>
                                                            :
                                                            
                                                            !res.Product_Price_Discounted ?
                                                                <>
                                                            <p style={{color  : "grey"  }}> <b>Rs : {res.Product_Price}</b>  </p>
                                                            <div style={{width : "10px"}}></div>
                                                            </>
                                                                                                                  
                                                        :
                                                            res.Product_Price_Discounted ? 
                                                        <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
                                                            <p  style={{color  : "black"  }}>Rs: {res.Product_Price_Discounted}</p>
                                                            <div style={{width : "10px"}}></div>
                                                            <p style={{color  : "grey"  }}> <b><s>Rs: {res.Product_Price}</s></b>  </p>
                                                        </div>
                                                        : 
                                                        <p style={{color  : "black"  }}>{res.Product_Price}</p>

                                                        }
                                                        <div onClick={()=>settoBags(res)} className="btn btn-dark btn-group-lg view-btn-detail">Add To Bag</div>
                                                        {/* <Link to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/"+res._id+"/"+res.Product_Name}  ><div className="btn btn-dark btn-group-lg view-btn-detail">View</div></Link> */}
                                                        {/* <Link to={"/categories/"+CategoriesName.categoriesName+"/"+CategoriesName.subcategories+"/"+res._id+"/"+res.Product_Name} onClick={()=>savethedetailproduct(res)} id="view-at-hover" ><div className="btn btn-dark btn-group-lg view-btn-detail">View</div></Link> */}

                                                    </div>
                                                </div>
                                               
                                            </div> 
                                        )  
                                        }
                                        else{
                                            return (
                                            <div className="col-xl-4 col-sm-12 col-lg-6 mb-12" data-aos="fade-up" style={{marginBottom : "30px"}}>
                                                <div className="block-4 text-center hover-product-card" style={{width: "300px"}}>
                                                <span style={{margin : "10px 4px" , color : "red", fontSize : "20px" , fontWeight : "bold",textAlign : "left"}}>
                                                {res.Size_Discounted[0] ?
                                                    <span>{(100 - ( res.Size_Discounted[0]*100) /  res.Price[0]).toFixed(2)}  % off</span>
                                                    : 
                                                res.Product_Price_Discounted ?
                                                    <span>{(100 - (res.Product_Price_Discounted *100) /  res.Product_Price).toFixed(2)}  % off</span>
                                                    : ""
                                                }
                                                </span>
                                                    <figure className="block-4-image">
                                                    {/* <Link to='/detail'> */}
                                                    <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res._id+"/"+res.Product_Name} ><img src={res.Product_Image_Upload}  style={{height : "210px" , width : "200px" }}  alt="Image placeholder" className="img-fluid" /></Link>
                                                    {/* </Link> */}
                                                    </figure>
                                                    {/* <div className="block-4-text p-4" onMouseEnter={()=>ViewAtDetails()} onMouseLeave={()=>NoViewAtDetails()} > */}
                                                    <div className="block-4-text p-4">
                                                        <h3><Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res._id+"/"+res.Product_Name}> {res.Product_Name}</Link></h3>
                                                        <p className="mb-0">{res.Product_Title}</p>
                                                        {
                                                            res.Size_Discounted[0] ?
                                                            <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
                                                            <p  style={{color  : "black"  }}>Rs: {res.Size_Discounted[0]}</p>
                                                            <div style={{width : "10px"}}></div>
                                                            <p style={{color  : "grey"  }}> <b><s>Rs: {res.Price[0]}</s></b>  </p>
                                                            <div style={{width : "10px"}}></div>
                                                            <p  style={{color  : "black"  }}>Size: {res.Size[0]}</p>
                                                            </div>
                                                            :
                                                            
                                                            !res.Product_Price_Discounted ?
                                                                <>
                                                            <p style={{color  : "grey"  }}> <b>Rs : {res.Product_Price}</b>  </p>
                                                            <div style={{width : "10px"}}></div>
                                                            </>
                                                                                                                  
                                                        :
                                                            res.Product_Price_Discounted ? 
                                                        <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
                                                            <p  style={{color  : "black"  }}>Rs: {res.Product_Price_Discounted}</p>
                                                            <div style={{width : "10px"}}></div>
                                                            <p style={{color  : "grey"  }}> <b><s>Rs: {res.Product_Price}</s></b>  </p>
                                                        </div>
                                                        : 
                                                        <p style={{color  : "black"  }}>{res.Product_Price}</p>

                                                        }
                                                        <div onClick={()=>settoBags(res)} className="btn btn-dark btn-group-lg view-btn-detail">Add To Bag</div>
                                                        {/* <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res._id+"/"+res.Product_Name} ><div className="btn btn-dark btn-group-lg view-btn-detail">View</div></Link> */}
                                                        {/* <Link to={"/categories/"+CategoriesName.categoriesName+"/"+CategoriesName.subcategories+"/"+res._id+"/"+res.Product_Name} onClick={()=>savethedetailproduct(res)} id="view-at-hover" ><div className="btn btn-dark btn-group-lg view-btn-detail">View</div></Link> */}

                                                    </div>
                                                </div>
                                               
                                            </div> 
                                        ) 
                                        }
                                        
                                    }) 
                                    :
                                     <span style={{margin:  "auto"}}>
                                        <h3></h3>
                                    </span>
                                    :
                                     <span style={{margin:  "auto"}}>
                                        {/* <h3>Loading ...</h3> */}
                                    </span>
                                    :
                                     <span style={{margin:  "auto"}}>
                                        {/* <h3 style={{paddingLeft: "50px"}}>No Any Product</h3> */}
                                    </span>
                                   }
                                     {/* <br />
                                     <br />
                                     <br /> */}
                                     {/* <center> */}
                                        
                                     {/* </center> */}
                                    
                                </div>
                                <div className="row mb-5">
                                   {dis ?
                                    product.length > 0 ?
                                    product.length === 2 ?
                                    product.map((res,i)=>{
                                        if(res.Product_Catagories === "books" || res.Product_Catagories === "Uniform" || res.Product_Catagories === "Stationery" ){
                                          return (
                                            <div className="col-xl-6 col-sm-12 col-lg-6 mb-12" data-aos="fade-up" style={{marginBottom : "30px",paddingLeft:"50px"}}>
                                                <div className="block-4 text-center hover-product-card" style={{width: "300px"}}>
                                                <span style={{margin : "10px 4px" , color : "red", fontSize : "20px" , fontWeight : "bold",textAlign : "left"}}>
                                                {res.Size_Discounted[0] ?
                                                    <span>{(100 - ( res.Size_Discounted[0]*100) /  res.Price[0]).toFixed(2)}  % off</span>
                                                    : 
                                                res.Product_Price_Discounted ?
                                                    <span>{(100 - (res.Product_Price_Discounted *100) /  res.Product_Price).toFixed(2)}  % off</span>
                                                    : ""
                                                }
                                                </span>
                                                    <figure className="block-4-image">
                                                    {/* <Link to='/detail'> */}
                                                    <Link to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/"+res._id+"/"+res.Product_Name} ><img src={res.Product_Image_Upload}  style={{height : "210px" , width : "200px" }}  alt="Image placeholder" className="img-fluid" /></Link>
                                                    {/* </Link> */}
                                                    </figure>
                                                    {/* <div className="block-4-text p-4" onMouseEnter={()=>ViewAtDetails()} onMouseLeave={()=>NoViewAtDetails()} > */}
                                                    <div className="block-4-text p-4">
                                                        <h3><Link to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/"+res._id+"/"+res.Product_Name} > {res.Product_Name}</Link></h3>
                                                        <p className="mb-0">{res.Product_Title}</p>
                                                        {
                                                            res.Size_Discounted[0] ?
                                                            <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
                                                            <p  style={{color  : "black"  }}>Rs: {res.Size_Discounted[0]}</p>
                                                            <div style={{width : "10px"}}></div>
                                                            <p style={{color  : "grey"  }}> <b><s>Rs: {res.Price[0]}</s></b>  </p>
                                                            <div style={{width : "10px"}}></div>
                                                            <p  style={{color  : "black"  }}>Size : {res.Size[0]}</p>
                                                            </div>
                                                            :
                                                            
                                                            !res.Product_Price_Discounted ?
                                                                <>
                                                            <p style={{color  : "grey"  }}> <b>Rs : {res.Product_Price}</b>  </p>
                                                            <div style={{width : "10px"}}></div>
                                                            </>
                                                                                                                  
                                                        :
                                                            res.Product_Price_Discounted ? 
                                                        <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
                                                            <p  style={{color  : "black"  }}>Rs: {res.Product_Price_Discounted}</p>
                                                            <div style={{width : "10px"}}></div>
                                                            <p style={{color  : "grey"  }}> <b><s>Rs: {res.Product_Price}</s></b>  </p>
                                                        </div>
                                                        : 
                                                        <p style={{color  : "black"  }}>{res.Product_Price}</p>

                                                        }
                                                        {/* <Link to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/"+res._id+"/"+res.Product_Name}  ><div className="btn btn-dark btn-group-lg view-btn-detail">View</div></Link> */}
                                                        <div onClick={()=>settoBags(res)} className="btn btn-dark btn-group-lg view-btn-detail">Add To Bag</div>
                                                        {/* <Link to={"/categories/"+CategoriesName.categoriesName+"/"+CategoriesName.subcategories+"/"+res._id+"/"+res.Product_Name} onClick={()=>savethedetailproduct(res)} id="view-at-hover" ><div className="btn btn-dark btn-group-lg view-btn-detail">View</div></Link> */}

                                                    </div>
                                                </div>
                                               
                                            </div> 
                                        )  
                                        }
                                        else{
                                            return (
                                            <div className="col-xl-6 col-sm-12 col-lg-6 mb-12" data-aos="fade-up" style={{marginBottom : "30px"}}>
                                                <div className="block-4 text-center hover-product-card" style={{width: "300px"}}>
                                                <span style={{margin : "10px 4px" , color : "red", fontSize : "20px" , fontWeight : "bold",textAlign : "left"}}>
                                                {res.Size_Discounted[0] ?
                                                    <span>{(100 - ( res.Size_Discounted[0]*100) /  res.Price[0]).toFixed(2)}  % off</span>
                                                    : 
                                                res.Product_Price_Discounted ?
                                                    <span>{(100 - (res.Product_Price_Discounted *100) /  res.Product_Price).toFixed(2)}  % off</span>
                                                    : ""
                                                }
                                                </span>
                                                    <figure className="block-4-image">
                                                    {/* <Link to='/detail'> */}
                                                    <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res._id+"/"+res.Product_Name} ><img src={res.Product_Image_Upload}  style={{height : "210px" , width : "200px" }}  alt="Image placeholder" className="img-fluid" /></Link>
                                                    {/* </Link> */}
                                                    </figure>
                                                    {/* <div className="block-4-text p-4" onMouseEnter={()=>ViewAtDetails()} onMouseLeave={()=>NoViewAtDetails()} > */}
                                                    <div className="block-4-text p-4">
                                                        <h3><Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res._id+"/"+res.Product_Name}> {res.Product_Name}</Link></h3>
                                                        <p className="mb-0">{res.Product_Title}</p>
                                                        {
                                                            res.Size_Discounted[0] ?
                                                            <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
                                                            <p  style={{color  : "black"  }}>Rs: {res.Size_Discounted[0]}</p>
                                                            <div style={{width : "10px"}}></div>
                                                            <p style={{color  : "grey"  }}> <b><s>Rs: {res.Price[0]}</s></b>  </p>
                                                            <div style={{width : "10px"}}></div>
                                                            <p  style={{color  : "black"  }}>Size: {res.Size[0]}</p>
                                                            </div>
                                                            :
                                                            
                                                            !res.Product_Price_Discounted ?
                                                                <>
                                                            <p style={{color  : "grey"  }}> <b>Rs : {res.Product_Price}</b>  </p>
                                                            <div style={{width : "10px"}}></div>
                                                            </>
                                                                                                                  
                                                        :
                                                            res.Product_Price_Discounted ? 
                                                        <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
                                                            <p  style={{color  : "black"  }}>Rs: {res.Product_Price_Discounted}</p>
                                                            <div style={{width : "10px"}}></div>
                                                            <p style={{color  : "grey"  }}> <b><s>Rs: {res.Product_Price}</s></b>  </p>
                                                        </div>
                                                        : 
                                                        <p style={{color  : "black"  }}>{res.Product_Price}</p>

                                                        }
                                                        {/* <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res._id+"/"+res.Product_Name} ><div className="btn btn-dark btn-group-lg view-btn-detail">View</div></Link> */}
                                                        <div onClick={()=>settoBags(res)} className="btn btn-dark btn-group-lg view-btn-detail">Add To Bag</div>
                                                        {/* <Link to={"/categories/"+CategoriesName.categoriesName+"/"+CategoriesName.subcategories+"/"+res._id+"/"+res.Product_Name} onClick={()=>savethedetailproduct(res)} id="view-at-hover" ><div className="btn btn-dark btn-group-lg view-btn-detail">View</div></Link> */}

                                                    </div>
                                                </div>
                                               
                                            </div> 
                                        ) 
                                        }
                                        
                                    }) 
                                    :
                                     <span style={{margin:  "auto"}}>
                                        <h3></h3>
                                    </span>
                                    :
                                     <span style={{margin:  "auto"}}>
                                        {/* <h3>Loading ...</h3> */}
                                    </span>
                                    :
                                     <span style={{margin:  "auto"}}>
                                        {/* <h3 style={{paddingLeft: "50px"}}>No Any Product</h3> */}
                                    </span>
                                   }
                                     {/* <br />
                                     <br />
                                     <br /> */}
                                     {/* <center> */}
                                        
                                     {/* </center> */}
                                    
                                </div>
                                <div className="row mb-5">
                                   {dis ?
                                    product.length > 0 ?
                                    product.length === 1 ?
                                    product.map((res,i)=>{
                                        if(res.Product_Catagories === "books" || res.Product_Catagories === "Uniform" || res.Product_Catagories === "Stationery"){
                                          return (
                                            <div className="col-xl-6 col-sm-12 col-lg-6 mb-12" data-aos="fade-up" style={{marginBottom : "30px",paddingLeft:"50px"}}>
                                                <div className="block-4 text-center hover-product-card" style={{width: "300px"}}>
                                                <span style={{margin : "10px 4px" , color : "red", fontSize : "20px" , fontWeight : "bold",textAlign : "left"}}>
                                                {res.Size_Discounted[0] ?
                                                    <span>{(100 - ( res.Size_Discounted[0]*100) /  res.Price[0]).toFixed(2)}  % off</span>
                                                    : 
                                                res.Product_Price_Discounted ?
                                                    <span>{(100 - (res.Product_Price_Discounted *100) /  res.Product_Price).toFixed(2)}  % off</span>
                                                    : ""
                                                }
                                                </span>
                                                    <figure className="block-4-image">
                                                    {/* <Link to='/detail'> */}
                                                    <Link to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/"+res._id+"/"+res.Product_Name} ><img src={res.Product_Image_Upload}  style={{height : "210px" , width : "200px" }}  alt="Image placeholder" className="img-fluid" /></Link>
                                                    {/* </Link> */}
                                                    </figure>
                                                    {/* <div className="block-4-text p-4" onMouseEnter={()=>ViewAtDetails()} onMouseLeave={()=>NoViewAtDetails()} > */}
                                                    <div className="block-4-text p-4">
                                                        <h3><Link to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/"+res._id+"/"+res.Product_Name} > {res.Product_Name}</Link></h3>
                                                        <p className="mb-0">{res.Product_Title}</p>
                                                        {
                                                            res.Size_Discounted[0] ?
                                                            <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
                                                            <p  style={{color  : "black"  }}>Rs: {res.Size_Discounted[0]}</p>
                                                            <div style={{width : "10px"}}></div>
                                                            <p style={{color  : "grey"  }}> <b><s>Rs: {res.Price[0]}</s></b>  </p>
                                                            <div style={{width : "10px"}}></div>
                                                            <p  style={{color  : "black"  }}>Size : {res.Size[0]}</p>
                                                            </div>
                                                            :
                                                            
                                                            !res.Product_Price_Discounted ?
                                                                <>
                                                            <p style={{color  : "grey"  }}> <b>Rs : {res.Product_Price}</b>  </p>
                                                            <div style={{width : "10px"}}></div>
                                                            </>
                                                                                                                  
                                                        :
                                                            res.Product_Price_Discounted ? 
                                                        <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
                                                            <p  style={{color  : "black"  }}>Rs: {res.Product_Price_Discounted}</p>
                                                            <div style={{width : "10px"}}></div>
                                                            <p style={{color  : "grey"  }}> <b><s>Rs: {res.Product_Price}</s></b>  </p>
                                                        </div>
                                                        : 
                                                        <p style={{color  : "black"  }}>{res.Product_Price}</p>

                                                        }
                                                        {/* <Link to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/"+res._id+"/"+res.Product_Name}  ><div className="btn btn-dark btn-group-lg view-btn-detail">View</div></Link> */}
                                                        <div onClick={()=>settoBags(res)} className="btn btn-dark btn-group-lg view-btn-detail">Add To Bag</div>
                                                        {/* <Link to={"/categories/"+CategoriesName.categoriesName+"/"+CategoriesName.subcategories+"/"+res._id+"/"+res.Product_Name} onClick={()=>savethedetailproduct(res)} id="view-at-hover" ><div className="btn btn-dark btn-group-lg view-btn-detail">View</div></Link> */}

                                                    </div>
                                                </div>
                                               
                                            </div> 
                                        )  
                                        }
                                        else{
                                            return (
                                            <div className="col-xl-6 col-sm-12 col-lg-6 mb-12" data-aos="fade-up" style={{marginBottom : "30px"}}>
                                                <div className="block-4 text-center hover-product-card" style={{width: "300px"}}>
                                                <span style={{margin : "10px 4px" , color : "red", fontSize : "20px" , fontWeight : "bold",textAlign : "left"}}>
                                                {res.Size_Discounted[0] ?
                                                    <span>{(100 - ( res.Size_Discounted[0]*100) /  res.Price[0]).toFixed(2)}  % off</span>
                                                    : 
                                                res.Product_Price_Discounted ?
                                                    <span>{(100 - (res.Product_Price_Discounted *100) /  res.Product_Price).toFixed(2)}  % off</span>
                                                    : ""
                                                }
                                                </span>
                                                    <figure className="block-4-image">
                                                    {/* <Link to='/detail'> */}
                                                    <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res._id+"/"+res.Product_Name} ><img src={res.Product_Image_Upload}  style={{height : "210px" , width : "200px" }}  alt="Image placeholder" className="img-fluid" /></Link>
                                                    {/* </Link> */}
                                                    </figure>
                                                    {/* <div className="block-4-text p-4" onMouseEnter={()=>ViewAtDetails()} onMouseLeave={()=>NoViewAtDetails()} > */}
                                                    <div className="block-4-text p-4">
                                                        <h3><Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res._id+"/"+res.Product_Name}> {res.Product_Name}</Link></h3>
                                                        <p className="mb-0">{res.Product_Title}</p>
                                                        {
                                                            res.Size_Discounted[0] ?
                                                            <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
                                                            <p  style={{color  : "black"  }}>Rs: {res.Size_Discounted[0]}</p>
                                                            <div style={{width : "10px"}}></div>
                                                            <p style={{color  : "grey"  }}> <b><s>Rs: {res.Price[0]}</s></b>  </p>
                                                            <div style={{width : "10px"}}></div>
                                                            <p  style={{color  : "black"  }}>Size: {res.Size[0]}</p>
                                                            </div>
                                                            :
                                                            
                                                            !res.Product_Price_Discounted ?
                                                                <>
                                                            <p style={{color  : "grey"  }}> <b>Rs : {res.Product_Price}</b>  </p>
                                                            <div style={{width : "10px"}}></div>
                                                            </>
                                                                                                                  
                                                        :
                                                            res.Product_Price_Discounted ? 
                                                        <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
                                                            <p  style={{color  : "black"  }}>Rs: {res.Product_Price_Discounted}</p>
                                                            <div style={{width : "10px"}}></div>
                                                            <p style={{color  : "grey"  }}> <b><s>Rs: {res.Product_Price}</s></b>  </p>
                                                        </div>
                                                        : 
                                                        <p style={{color  : "black"  }}>{res.Product_Price}</p>

                                                        }
                                                        {/* <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res._id+"/"+res.Product_Name} ><div className="btn btn-dark btn-group-lg view-btn-detail">View</div></Link> */}
                                                        <div onClick={()=>settoBags(res)} className="btn btn-dark btn-group-lg view-btn-detail">Add To Bag</div>
                                                        {/* <Link to={"/categories/"+CategoriesName.categoriesName+"/"+CategoriesName.subcategories+"/"+res._id+"/"+res.Product_Name} onClick={()=>savethedetailproduct(res)} id="view-at-hover" ><div className="btn btn-dark btn-group-lg view-btn-detail">View</div></Link> */}

                                                    </div>
                                                </div>
                                               
                                            </div> 
                                        ) 
                                        }
                                        
                                    }) 
                                    :
                                     <span style={{margin:  "auto"}}>
                                        <h3></h3>
                                    </span>
                                    :
                                    
                                    <center>
                                     {/* <span style={{margin:  "auto"}}> */}
                                        <h3 style={{paddingLeft: "50px"}}>No Any Product</h3>
                                    {/* </span> */}
                                    </center>

                                    :
                                     <span style={{margin:  "auto"}}>
                                        <h3>Loading ...</h3>
                                    </span>
                                   }
                                     {/* <br />
                                     <br />
                                     <br /> */}
                                     {/* <center> */}
                                        
                                     {/* </center> */}
                                    
                                </div>

                               







                                      
                                       
                            </div>

                                       
                                </div> 
                                <center>

                                <h4 style={{margin:  "auto"}}>
                                         
                                    <Link to="/arrival/product">See More</Link>
                                </h4>
                                </center>
                                <br />
                </section>






{/* 

                <div className="container-fuild" style={{width : "99% " ,padding : "0px 10px" }}>
                        <div className="row">

                            <div className="block-none-cato col-xl-2 col-lg-2">
                                <div className="border p-4 rounded mb-4" style={{background : "rgb(242, 242, 242)" , height : "300px"}}>
                                        <h3 className="mb-3 h6 text-uppercase text-black d-block">Categories</h3>
                                        <ul className="list-unstyled mb-0">
                                        {
                                            Categories.map((res,i)=>{
                                                var Cat1 = res.Categories.split(" ").join("-")
                                                return( <li className="mb-1" key={i}>
                                                            <Link   onClick={()=>cate(res.Categories)} className="d-flex">
                                                                <span>{res.Categories}</span> 
                                                                <span className="text-black ml-auto">(2,220)</span>
                                                            </Link>
                                                        </li>

                                                )

                                            })
                                        }
                                       
                                        </ul>
                                    </div>
                                </div>
                            <div className="col-xl-10 col-lg-10 col-md-12">
                                <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <img class="d-block w-100" src={blog_1} alt="First slide"  style={{height:"300px"}}/>
                                        </div>
                                        <div class="carousel-item">
                                            <img class="d-block w-100" src={blog_1} alt="Second slide"   style={{height:"300px"}}/>
                                        </div>
                                        <div class="carousel-item">
                                            <img class="d-block w-100" src={blog_1} alt="Third slide"   style={{height:"300px"}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div> */}


                {/* <div className="site-section block-3 site-blocks-2"  style={{background : "#66605b"}}>
                    <div className="container-fuild" style={{width : "98%"}}>
                        <div className="row justify-content-center">
                            <div className="col-md-7 site-section-heading text-center pt-4 ">
                                <h2 style={{color : "white",marginLeft : "-34px"}} >Popular Products</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 p-5">
                            <Carousel responsive={responsive}>
                            {
                                product.map((res,i)=>{
                                    if(res.Product_Popular && res.Product_Popular === "yes"){
                                        var Cat = res.Product_Catagories.split(" ").join("-")
                                        
                                        var Cat1 = res.Product_Name.split(" ").join("-")
                                        return (<div className="item" style={{margin : "5px 5px" }} key={i}>
                                        <div className="block-4 text-center" style={{margin : "10px 0px" , height : "430px"}} >
                                            <figure className="block-3-image">
                                                <img src={res.Product_Image_Upload} alt="Image placeholder" style={{height : "240px" }}  className="img-fluid"/>
                                            </figure>
                                            <div className="block-4-text p-1">
                                                <h3><Link to='/shop'><div href="#">{res.Product_Name}</div></Link></h3>
                                                <p className="mb-0">{res.Product_Title}</p>
                                                <p className="text-primary font-weight-bold">Rs : {res.Product_Price}</p>
                                                <Link to={"/shop/categories/"+Cat+"/"+Cat1}  onClick={()=>savethedetailproduct(res)}><div className="btn btn-sm btn-primary">View</div></Link>

                                            </div>
                                        </div>
                                    </div>
                                    )
                                    }
                                    
                                })
                            }
                                   
                                   

                </Carousel>
                            </div>
                        </div>
                    </div>
                </div> */}




                <div className="site-section site-section-sm site-blocks-1 backk" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    {/* <span className="icon-truck"></span> */}
                                    <span><AirportShuttleIcon  className="icon-truck" style={{fontSize : "65px"}}/></span>
                                </div>
                                <div className="text">
                                    <h2 className="text-uppercase" style={{color :  "white"}}>Shipping Charges</h2>
                                    <p>Shipping Fee Will be Calculated on Checkout.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="100">
                                <div className="icon mr-4 align-self-start">
                                    {/* <span className="icon-refresh2"></span> */}
                                    <span><ReplayIcon    style={{fontSize : "65px"}}  /></span>
                                </div>
                                <div className="text">
                                    <h2 className="text-uppercase" style={{color :  "white"}}>Free Returns</h2>
                                    <p>Vendor will be take care of Return and Exchange.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="200">
                                <div className="icon mr-4 align-self-start">
                                    {/* <span className="icon-help"></span> */}
                                    <span><HelpOutlineIcon    style={{fontSize : "65px"}}  /></span>

                                </div>
                                <div className="text">
                                    <h2 className="text-uppercase" style={{color :  "white"}}>Customer Support</h2>
                                    <p>Student1Stop give 24/7 Customer Support.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





  <div className="site-section site-section-sm site-blocks-1 m-3" >
                    <div className="container-fuild">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12" data-aos="fade-up" data-aos-delay="">
                                {/* <img src={bigPhoto} alt="" style={{width : "100%" , height : "200px"}}/> */}
                                {BannerPhoto.length > 0
                                     ? 
                                    BannerPhoto.map((res,i)=>{
                                        return (
                                        <img src={res.photo1} alt="" style={{width : "100%" ,objectFit: "contain"}} className="jkkukk" />
                                        // <img src={res.photo1} alt="" style={{width : "100%" ,height:"240px"}} className="jkkukk" />
                                        )
                                    })
                                    :
                                        " "
                                }
                            </div>
                        </div>
                    </div>
                </div>









{/* 

                <div className="site-section block-3 site-blocks-2" style={{background : "#66605b"}}>
                    <div className="container-fuild" style={{width : "98%"}} >
                        <div className="row justify-content-center">
                            <div className="col-md-7 site-section-heading text-center pt-4">
                                <h2 style={{color : "white",marginLeft : "-44px"}} >Homeopathic & Herbal Products</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 p-5">
                            <Carousel responsive={responsive}>
                                        {
                                            Homo.map((res,i)=>{
                                                var Cat = res.Product_Catagories.split(" ").join("-")
                                        
                                                var Cat1 = res.Product_Name.split(" ").join("-")
                                                return (
                                                     <div className="item" style={{margin : "5px 5px"}} key={i}>
                                                        <div className="block-4 text-center" style={{margin : "10px 0px" , height : "430px"}} >
                                                            <figure className="block-3-image">
                                                                <img src={res.Product_Image_Upload} alt="Image placeholder" style={{height : "210px" }}  className="img-fluid"/>
                                                            </figure>
                                                            <div className="block-4-text p-1">
                                                                <h3><Link to='/shop'><div href="#">{res.Product_Name}</div></Link></h3>
                                                                <p className="mb-0">{res.Product_Title}</p>
                                                                <p className="text-primary font-weight-bold">Rs : {res.Product_Price}</p>
                                                                <Link to={"/shop/categories/"+Cat+"/"+Cat1} onClick={()=>savethedetailproduct(res)}><div href="#" className="btn btn-sm btn-primary">View</div></Link>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                       
                                   

                </Carousel>
                            </div>
                        </div>
                    </div>
                </div> */}




                {/* <div className="container" style={{width : "99% " ,padding : "0px 10px"}}>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div>
                                    <h1>Dawaai Pvt Ltd.</h1>
                                    <p>Dawaai is Pakistan’s leading digital healthcare pharmacy. Our platform enables you to order medicines online from the comfort of your home and get them delivered to your doorstep. Dawaai is the first internationally certified and registered healthcare merchant in Pakistan (LegitScript Certified). We pride ourselves in providing authentic medicines, equipment and supplements to our users. Over the years we have built trust in our customers by being accessible and providing authentic content to help them make better health choices.</p>
                                    <p>We serve patients and consumers all across Pakistan (Karachi, Lahore, Islamabad and more). From providing online medicines, lab tests, online doctor consultation and medical content Dawaai offers it all. Using our fast delivery service, you can get selected products within 2 hours of your order confirmation. Our mobile application is available on iOS and android.</p>
                                    <h4>ONLINE PHARMACY</h4>
                                    <p>Dawaai is an online pharmacy that sells medicines at a discounted rate offering the lowest1in Pakistan. The process works by uploading a prescription that our pharmacist will verify, creating an order for you. We offer medicines from reputable brands like Pfizer, GSK and Getz Pharma.</p>
                                    <h4>DOCTOR CONSULTATION</h4>
                                    <p>Dawaai’s Ask a Doctor service is safe and easy. We offer free chat and paid video consultations through appointments. We have a multi-disciplinary team of highly qualified doctors from General Physicians, gynecologists, Chest specialists, Dermatologists, Nutritionists to Cardiologists that give priority to patient doctor confidentiality.</p>
                                    <h4>LAB TESTS</h4>
                                    <p>Order online lab tests at discounted rates on the Dawaai website and application. We provide online patient reports and lab packages from CHS and Chughtai Labs.</p>
                                </div>
                            </div>
                        </div>
                </div> */}

{/* 

                <div className="container">
                    <div className="row">
                            <div className="col-md-12">
                                <div className="site-section site-blocks-2">
                                    <div className="row justify-content-center text-center mb-5">
                                        <div className="col-md-7 site-section-heading pt-4">
                                            <h2>Categories</h2>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {Categories && Categories.map((res,i)=>{
                                            var Cat1 = res.Categories.split(" ").join("-")

                                            return(
                                                <div key={i} className="col-sm-6 col-md-6 col-lg-4 mb-4 mb-lg-0" style={{margin: "10px 0px"}} data-aos="fade" data-aos-delay="">
                                                    <Link className="block-2-item"  onClick={()=>cate(res.Categories)}>
                                                        <figure className="image">
                                                            <img src={categ} alt="" className="img-fluid" style={{height : "500px"}}/>
                                                        </figure>
                                                        <div className="text">
                                                            <span className="text-uppercase">Collections</span>
                                                            <h3>{res.Categories}</h3>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        })
                                            
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}


{/* 

                <div className="site-section site-blocks-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 col-md-6 col-lg-4 mb-4 mb-lg-0" data-aos="fade" data-aos-delay="">
                                <a className="block-2-item" href="#">
                                    <figure className="image">
                                        <img src={women} alt="" className="img-fluid" />
                                    </figure>
                                    <div className="text">
                                        <span className="text-uppercase">Collections</span>
                                        <h3>Women</h3>
                                    </div>
                                </a>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4 mb-5 mb-lg-0" data-aos="fade" data-aos-delay="100">
                                <a className="block-2-item" href="#">
                                    <figure className="image">
                                        <img src={children} alt="" className="img-fluid" />
                                    </figure>
                                    <div className="text">
                                        <span className="text-uppercase">Collections</span>
                                        <h3>Children</h3>
                                    </div>
                                </a>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4 mb-5 mb-lg-0" data-aos="fade" data-aos-delay="200">
                                <a className="block-2-item" href="#">
                                    <figure className="image">
                                        <img src={men} alt="" className="img-fluid" />
                                    </figure>
                                    <div className="text">
                                        <span className="text-uppercase">Collections</span>
                                        <h3>Men</h3>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
 */}














































































{/* 
                <div className="site-section block-8">
                    <div className="container">
                        <div className="row justify-content-center  mb-5">
                            <div className="col-md-7 site-section-heading text-center pt-4">
                                <h2>Big Sale!</h2>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-md-12 col-lg-7 mb-5">
                                <a href="#"><img src={blog_1} alt="Image placeholder" className="img-fluid rounded" /></a>
                            </div>
                            <div className="col-md-12 col-lg-5 text-center pl-md-5">
                                <h2><a href="#">50% less in all items</a></h2>
                                <p className="post-meta mb-4">By <a href="#">Carl Smith</a> <span className="block-8-sep">&bullet;</span> September 3, 2018</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam iste dolor accusantium facere corporis ipsum animi deleniti fugiat. Ex, veniam?</p>
                                <p><Link to='/shop'><div className="btn btn-primary btn-sm">Shop Now</div></Link></p>
                            </div>
                        </div>
                    </div>
                </div> */}







            </div>
        )
    
}

export default Home;