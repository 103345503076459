import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

const Contact = (props)=>  {


    useEffect(() => {
        window.scrollTo(0, 0)

        document.getElementById("mobile-site-hide-show11").style.display="none"
    }, []);




        return (
            <div>
                <div className="bg-light py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Shipping Policy</strong></div>
                        </div>
                    </div>
                </div>
                <div className="container" style={{width : "99% " ,padding : "80px 30px"}}>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div>
                                    <h3>Shipping and Delivery Policy Details – Student1Stop.com</h3>
                                    <p>We usually ship all items in order within three working days. While Student1Stop.com will do their best to ship all items in order within three working days, this will not always be guaranteed in cases where there is a delay in purchasing the item or if the item is no longer available. Student1Stop.com will make reasonable efforts to notify customers in the event of such a delay. </p>
                                    <p>Estimated shipping times are provided only for your convenience and no Student1Stop.com shipping providers will be liable for any damages or losses due to failure to meet shipping limitations. It is not possible for Student1Stop.com delivery providers to experience delivery delays due to public holidays, strikes, natural disasters, bad weather, government actions or any other situation beyond their control. This Shipping and Delivery Policy may change without notice.</p>
                                    <p>Retry delivery will be made should the first attempt fail. It is the customer's responsibility to be available at the delivery address to receive delivery. If the delivery is not accepted or missed despite the retry, the delivery package will be returned to the warehouse and the order will be canceled. Any requests to reship the parcel from the warehouse will not be entertained.</p>
                                    <p>In the event of COD orders, it must be done before receiving the package, at the time of delivery. The package cannot be opened and checked during delivery.
</p>
                                    <p>Effective Date: This Shipping and Delivery Policy was last updated on July 11, 2021.</p>
                                    
                                    
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        )
    
}


export default Contact;