import React, {useState , useEffect} from 'react';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';

const Checkout = (props) => {
    const [fname , setfName ] = useState("")
    // const [Lname , setLName ] = useState("")
    const [Address , setAddress ] = useState("")
    const [Address1 , setAddress1 ] = useState("")
    // const [ZipPostal , setZipPostal ] = useState("")
    const [Email , setEmail ] = useState("")
    const [Phone , setPhone ] = useState("")
    const [admindataall , setadmindataall ] = useState(0)
    const [Order_Notes , setOrder_Notes ] = useState("")
    const [cart , setCart] = useState([])
    const [tot , setot] = useState(0)
    const [total , settotal] = useState(0)
    const [doctor , setdoctor] = useState(0)
    const [Num1111 , setNum1111] = useState(0)
    const [discount , setdiscount ] = useState({})

    const [range1,setRange1] = useState([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0])
    const [range,setRange] = useState([])
    const [range2,setRange2] = useState([])

    const [display , setdisplay] = useState(true)
    const [play , setplay] = useState(false)
    // const [Product_Image_Upload , setProduct_Image_Upload] = useState("")




    useEffect(() =>{
        window.scrollTo(0, 0)
        // if(! JSON.parse(localStorage.getItem("user main")) )  {
        //     props.history.push("/user/login")
        // }  
        
        var data =  JSON.parse(localStorage.getItem("Cart")) 
        // var data1 =  JSON.parse(localStorage.getItem("CartPrice")) 
        if (data) {
            setCart(data)
            
            data.map((res,i)=>{
                range1[i] = res.ProductCreater
                range.push(res.ProductCreater)
                range2.push(res)
            })
            
            setRange1(range1)
            
            setRange(range)
            let hhh =[... new Set(range)]
            setRange(hhh)
            // console.log(range1,range,hhh)
            let fg =  []
            // let fg1 =  []
            for (let i = 0; i < hhh.length; i++) {
                    let ab = parseInt(Math.random()*4)
                let ab1 = parseInt(Math.random()*8)
                let ab2 = parseInt(Math.random()*1)
                let ab3 = parseInt(Math.random()*7)
                let ab4 = parseInt(Math.random()*3)
                // let ab5 = parseInt(Math.random()*99)
                // let ab6 = parseInt(Math.random()*53)
                let leng = ab+""+ab1+""+ab2+""+ab3+""+ab4
                fg.push(leng)

            }
            for (let i = 0; i < range2.length; i++) {
                hhh.map((res,u)=>{
                    if(range2[i].ProductCreater === res){
                        range2[i] = {
                            ...range2[i] , otpId : fg[u]
                        }
                        // fg1.push({
                        //     ...range2[i] , otpId : fg[u]
                        // })
                    }
              
                })
            }
            console.log(range2)
            setCart(range2)

            // setot(JSON.parse(localStorage.getItem("CartPrice")) )

            if( JSON.parse(localStorage.getItem("user main")) )
            {
            setfName(JSON.parse(localStorage.getItem("user main")).user)
            setAddress(JSON.parse(localStorage.getItem("user main")).address)
            setEmail(JSON.parse(localStorage.getItem("user main")).email)
            setPhone(JSON.parse(localStorage.getItem("user main")).phone)
            } 
  fetch("https://student1stop-backend-vercel.vercel.app/AllUserCheckoutData",{
    method: "GET",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,
})
.then(res181=>res181.json())
.then(res191=>{
    // setNum1111(res191.length)
    let date = new Date()
    let day= date.getDate() 
    let month= (date.getUTCMonth()+1)
    let year= date.getUTCFullYear()
    if(date.getDate() < 10) day = "0"+ date.getDate()
    if(date.getUTCMonth()+1 < 10) month = "0"+ (date.getUTCMonth()+1)
    
    let c = day +""+month+ ""+(year -2000)
    const f = res191.filter((res,i)=>res.Date === c)
    console.log(f,c)
    setNum1111(f.length)

})
  fetch("https://student1stop-backend-vercel.vercel.app/admindata",{
    method: "GET",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,
})
.then(res18=>res18.json())
.then(res19=>{
    setdoctor(res19[0].condition)
    console.log(res19[0].DeliveryCharges,JSON.parse(localStorage.getItem("CartPrice")));
  setadmindataall(res19[0].DeliveryCharges)

  let j = 0
  if(JSON.parse(localStorage.getItem("CartPrice")) < res19[0].condition){
                j = res19[0].DeliveryCharges
            }
            else{
                j = 0
            }
            data.map((item,i)=>{
               j = item.Total_Product_Price + j
            //    if (item.doctor_prescription){
            //     localStorage.setItem("doctor",JSON.stringify(true))
            //    }
            })
            // var doc =  JSON.parse(localStorage.getItem("doctor")) 
            // setdoctor(doc)

            if(JSON.parse(localStorage.getItem("discount")) ){
                setdiscount(JSON.parse(localStorage.getItem("discount")))
                var d = document.getElementById("discount")
                if(JSON.parse(localStorage.getItem("discount")).discount > 1){
                    d.innerHTML = "Rs " +JSON.parse(localStorage.getItem("discount")).discount
                    document.getElementById("ci11").style.visibility = "visible"
                    j = j - JSON.parse(localStorage.getItem("discount")).discount
                }
                else{
                    d.innerHTML = "Rs " +JSON.parse(localStorage.getItem("discount")).discount1 +" % ."
                    j = j - (j/JSON.parse(localStorage.getItem("discount")).discount1)

                }
            }


            setot(j)
            var t = document.getElementById("subtotal")
            t.innerHTML = "Rs "+j
            var f = document.getElementById("total")
            f.innerHTML = "Rs " +j

        })

        }
        // console.log(total,cart,j)
   else{
            var t = document.getElementById("subtotal")
            t.innerHTML = "Rs "+0
            var f = document.getElementById("total")
            f.innerHTML = "Rs " +0
   }
  },[])
  

//   useEffect(() => {
  
//     return () => {
//         localStorage.removeItem("doctor")
//     }
//   }, [])


    const SubmitDataMessage = (e) =>{
        e.preventDefault()
        console.log(cart)
        // fetch("https://student1stop-backend-vercel.vercel.app/admindata",{
        //                         method: "POST",
        //                         headers :  {
        //                             "Content-Type" : "application/json" , 
        //                         }
        //                     })
        //                     .then(res=>res.json())
        //                     .then((res1)=>{ 
        // if ( JSON.parse(localStorage.getItem("User"))  ){
            if (cart.length  >= 1 ){
                setdisplay(false)
        console.log("wddde");
               let j
        if(JSON.parse(localStorage.getItem("CartPrice")) < doctor){
                 j = admindataall
            }
            else{
                 j = 0
            }
            console.log(j,tot)
            let date = new Date()
            let f= date.getDate() +" "+(date.getUTCMonth()+1)+ " "+date.getUTCFullYear()
            let day= date.getDate() 
            let month= (date.getUTCMonth()+1)
            let year= date.getUTCFullYear()
            year = year - 2000

            if(date.getDate() < 10) day = "0"+ date.getDate()
            if(date.getUTCMonth()+1 < 10) month = "0"+ (date.getUTCMonth()+1)

            let c = day +""+month+ ""+year

            console.log(day , month , year,c,fname,
                            Address ,
                            Address1 ,
                            // UserId : JSON.parse(localStorage.getItem("user main"))._id ,
                            // ZipPostal ,
                            Email ,
                            Phone ,
                            Order_Notes,
                            discount ,
                            j,
                             cart,
                             Num1111,
                             c  )
                fetch("https://student1stop-backend-vercel.vercel.app/UsergetDatafromclient",{
                        method: "POST",
                        headers :  {
                            "Content-Type" : "application/json" , 
                        } ,
                        body : JSON.stringify({
                            fname,
                            Address ,
                            Address1 ,
                            // UserId : JSON.parse(localStorage.getItem("user main"))._id ,
                            // ZipPostal ,
                            Email ,
                            Phone ,
                            Order_Notes,
                            discount ,
                            DeliveryCharges:j,
                            Order : cart,
                            Num123 : Num1111,
                            Date1 : c ,

                        }),
                    })
                    .then(res=>res.json())
                    .then((res2)=>{        
                    if(res2.Error){
                        swal("Plz Refresh And try Again the Order");
                     }
                     else{
                        setdisplay(true)
                        swal("Successfully Submit your Order!");
                        // fetch("https://student1stop-backend-vercel.vercel.app/user-cart-order",{
                        //     method: "POST",
                        //     headers :  {
                        //         "Content-Type" : "application/json" , 
                        //     } ,
                        //     body : JSON.stringify({
                        //         Order : cart ,
                        //         user : JSON.parse(localStorage.getItem("User")) 
                        //     })
                        // })
                        // .then(res10=>res10.json())
                        // .then((res11)=>{ 
                        //     console.log(res11);
                        // })
                        // fetch("https://student1stop-backend-vercel.vercel.app/user-cart-detail",{
                        //     method: "POST",
                        //     headers :  {
                        //         "Content-Type" : "application/json" , 
                        //     } ,
                        //     body : JSON.stringify({
                        //         Details : {
                        //             fname,
                        //             Lname ,
                        //             CName, 
                        //             Address ,
                        //             Address1 ,
                        //             StateCountry  ,
                        //             ZipPostal ,
                        //             Email ,
                        //             Phone ,
                        //             Date : c ,
                        //             Order_Notes,
                        //             Select_Country,
                        //         },
                        //         user : JSON.parse(localStorage.getItem("User")) 
                        //     })
                        // })
                        // .then(res6=>res6.json())
                        // .then((res7)=>{ 
                        //     console.log(res7);
                        // })

                            setfName ("")
                            // setLName ("" )
                            setAddress  ("")
                            setAddress1 ("" )
                            // setZipPostal ("" )
                            setEmail ("" )
                            setPhone ("" )
                            setOrder_Notes ("")
                        localStorage.removeItem("Cart")
                        localStorage.removeItem("doctor")
                        localStorage.removeItem("SearchData")
                        localStorage.removeItem("Data")
                        localStorage.removeItem("CartPrice")
                        localStorage.removeItem("CateProduct")
                        props.history.push(`/thankyou`)

                     }
                  })
                  .catch(err=>{
                    swal("There is an Error");
                })
            }
            else{
                swal("There is No Any Product in Your Cart");
                setTimeout(()=>{
                    
                    props.history.push(`/`)
                },2000)
            }
        // })
    }
        return (
            <div>
                <div className="bg-light py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <Link to="/cart">Cart</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Checkout</strong></div>
                        </div>
                    </div>
                </div>
            <form onSubmit={(e)=>SubmitDataMessage(e)}>
                <div className="site-section">
                    <div className="container">
                        {/* <div className="row mb-5">
                            <div className="col-md-12">
                                <div className="border p-4 rounded" role="alert">
                                    Returning customer? <Link to="/login">Click here</Link> to login
                                </div>
                            </div> */}
                        {/* </div> */}
                        <div className="row">
                            <div className="col-md-6 mb-5 mb-md-0">
                                <h2 className="h3 mb-3 text-black">Billing Details</h2>
                                <div className="p-3 p-lg-5 border">
                                    
                                    <div className="form-group row">
                                        <div className="col-md-12">
                                            <label htmlFor="c_fname" className="text-black">Full Name <span className="text-danger">*</span></label>
                                            <input type="text" required value={fname} onChange={(e)=>setfName(e.target.value)}  className="form-control" id="c_fname" name="c_fname" />
                                        </div>
                                        {/* <div className="col-md-6">
                                            <label htmlFor="c_lname" className="text-black">Last Name <span className="text-danger">*</span></label>
                                            <input type="text" required value={Lname} onChange={(e)=>setLName(e.target.value)}  className="form-control" id="c_lname" name="c_lname" />
                                        </div> */}
                                    </div>

                                    

                                    <div className="form-group row">
                                        <div className="col-md-12">
                                            <label htmlFor="c_address" className="text-black">Address <span className="text-danger">*</span></label>
                                            <input type="text" required value={Address} onChange={(e)=>setAddress(e.target.value)}   className="form-control" id="c_address" name="c_address" placeholder="Street address" />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <input type="text" value={Address1} onChange={(e)=>setAddress1(e.target.value)}  className="form-control" placeholder="Apartment, suite, unit etc. (optional)" />
                                    </div>

                                    {/* <div className="form-group row">
                                        
                                        <div className="col-md-12">
                                            <label htmlFor="c_postal_zip" className="text-black">Postal / Zip <span className="text-danger">*</span></label>
                                            <input type="number" required value={ZipPostal} onChange={(e)=>setZipPostal(e.target.value)}  className="form-control" id="c_postal_zip" name="c_postal_zip" />
                                        </div>
                                    </div> */}

                                    <div className="form-group row mb-5">
                                        <div className="col-md-6">
                                            <label htmlFor="c_email_address" className="text-black">Email Address <span className="text-danger">*</span></label>
                                            <input type="email" required value={Email} onChange={(e)=>setEmail(e.target.value)} className="form-control" id="c_email_address" name="c_email_address" />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="c_phone" className="text-black">Phone <span className="text-danger">*</span></label>
                                            <input type="number" required value={Phone} onChange={(e)=>setPhone(e.target.value)}   className="form-control" id="c_phone" name="c_phone" placeholder="Phone Number" />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="c_order_notes" className="text-black">Order Notes</label>
                                        <textarea name="c_order_notes"  value={Order_Notes} onChange={(e)=>setOrder_Notes(e.target.value)} id="c_order_notes" cols="30" rows="5" className="form-control" placeholder="Write your notes here..."></textarea>
                                    </div>

                                   
                                    
                                </div>
                                
                            </div>
                            <div className="col-md-6">

                                

                                <div className="row mb-5">
                                    <div className="col-md-12">
                                        <h2 className="h3 mb-3 text-black">Your Order</h2>
                                        <div className="p-3 p-lg-5 border">
                                            <table className="table site-block-order-table mb-5">
                                                <thead>
                                                    <th>Product</th>
                                                    <th>Qty</th>
                                                    <th>Total</th>
                                                </thead>
                                                <tbody>
                                                {
                                                    cart && cart.map((item,i)=>{
                                                        return(<tr>
                                                        <td>{item.Product_Name}</td>
                                                        <td>x {item.Pieces}</td>
                                                        <td>Rs {item.Total_Product_Price}</td>
                                                    </tr>)
                                                    })
                                                }
                                                <tr>
                                                        <td className="text-black font-weight-bold"><strong>Shipping Charges</strong></td>
                                                        <td className="text-black font-weight-bold"></td>
                                                        <td className="text-black"> 
                                                        {/* Rs : 
                                                        {admindataall
                                                           
                                                        }  */}
                                                       { doctor !== 0 ?  tot < doctor ?
                                                            "Rs : "+admindataall
                                                            : 
                                                            <b>Free</b>
                                                            : "" }
                                                        </td>
                                                    </tr>
                                                    <tr id="ci11">
                                                        <td className="text-black font-weight-bold"><strong>Discount</strong></td>
                                                        <td className="text-black font-weight-bold"></td>
                                                        <td className="text-black font-weight-bold" id="discount"></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-black font-weight-bold"><strong>Cart Subtotal</strong></td>
                                                        <td className="text-black font-weight-bold"></td>
                                                        <td className="text-black" id="subtotal"></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-black font-weight-bold"><strong>Order Total</strong></td>
                                                        <td className="text-black font-weight-bold"></td>
                                                        <td className="text-black font-weight-bold" id="total"><strong></strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <div className="border p-3 mb-3">
                                                <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank" role="button" aria-expanded="false" aria-controls="collapsebank">Cash on Delivery</a></h3>

                                                <div className="collapse" id="collapsebank">
                                                    <div className="py-2">
                                                        <p className="mb-0">Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order won’t be shipped until the funds have cleared in our account.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="border p-3 mb-3">
                                                <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsecheque" role="button" aria-expanded="false" aria-controls="collapsecheque">Cheque Payment</a></h3>

                                                <div className="collapse" id="collapsecheque">
                                                    <div className="py-2">
                                                        <p className="mb-0">Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order won’t be shipped until the funds have cleared in our account.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="border p-3 mb-5">
                                                <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsepaypal" role="button" aria-expanded="false" aria-controls="collapsepaypal">Paypal</a></h3>

                                                <div className="collapse" id="collapsepaypal">
                                                    <div className="py-2">
                                                        <p className="mb-0">Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order won’t be shipped until the funds have cleared in our account.</p>
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className="form-group">

                                               {/* <Link to='thankyou'> */}
                                               {display ?
                                                <button type="submit" className="btn btn-primary btn-lg py-3 btn-block">Place Order</button>
                                                :
                                                <span  className="btn btn-primary btn-lg py-3 btn-block">Place Order</span>


                                               }
                                                {/* </Link> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </form>

            </div>
        )
    
}

export default Checkout;